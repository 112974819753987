<template>
  <div class="promos-section">
    <div class="promos-section__container first-section pb-0">

      <h2
          class="promos-section__title"
          :class="[xsOnly? '': 'mb-0']"
          v-html="$t(`landing.promos.${`title_${xsOnly? 'mobile': 'desktop'}`}`, {members: USERS_COUNT})"/>
      <h3
          class="promos-section__subtitle d-md-block d-none"
          v-html="$t(`landing.promos.title_2_desktop`)"/>

    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'PromosTitleSection',
  computed: {
    ...mapGetters('users', [
      'USERS_COUNT',
    ]),

  },
  methods: {
    ...mapActions('users', [
      'FETCH_USERS_COUNT',
    ]),
    load() {
      this.FETCH_USERS_COUNT();

    }
  },

  mounted() {
    this.load()
  },
}
</script>



<style scoped>

</style>
