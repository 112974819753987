export default {
    namespaced: true,
    state: {
        user_count: 0,
    },

    getters: {
        USERS_COUNT(state) {
            return state.user_count;
        }
    },

    actions: {
        async FETCH_USERS_COUNT({state, commit}, params = '') {
            return await axios.get('/users-count').then((response) => {
                commit('SET_USERS_COUNT', response.data.users_count);
            });
        },
    },

    mutations: {
        SET_USERS_COUNT(state, data) {
            state.user_count = data;
        },
    }
}
