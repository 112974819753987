<template>
  <div class="landing-view">
    <default-space>
      <el-row class="align-items-start">
        <el-col :span="24" >
          <promos-title-section/>
        </el-col>
      </el-row>
      <el-row justify="center" :gutter="20" class="align-items-start">
        <el-col :span="24" :md="18" class="pt-md-2">
          <promos-section/>
          <popular-folders-section/>
          <new-promos-section/>
          <banner-section v-if="LOCALE==='nl'"/>
          <folder-announcement-section/>
          <final-chance-section/>
          <news-section/>
        </el-col>
        <el-col :span="24" :md="6" class="promos-section__popular pt-md-2">
          <popular-aside/>
        </el-col>
      </el-row>
    </default-space>


    <copied-discount-code-dialog></copied-discount-code-dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {needAuth} from '@/mixins/auth/needAuth';
import PromosSection from '@/views/feed/sections/PromosSection.vue';
import PopularFoldersSection from '@/views/feed/sections/PopularFoldersSection.vue';
import NewPromosSection from '@/views/feed/sections/NewPromosSection.vue';
import BannerSection from '@/views/feed/sections/BannerSection.vue';
import FolderAnnouncementSection from '@/views/feed/sections/FolderAnnouncementSection.vue';
import FinalChanceSection from '@/views/feed/sections/FinalChanceSection.vue';
import NewsSection from '@/views/feed/sections/NewsSection.vue';
import DefaultSpace from '@/views/DefaultSpace.vue';
import CopiedDiscountCodeDialog from '@/components/feed/post/discount/CopiedDiscountCodeDialog.vue';
import PopularAside from '@/views/feed/sections/PopularAside.vue';
import PromosTitleSection from '@/views/feed/sections/PromosTitleSection.vue';

export default {
  name: 'LandingView',
  components: {
    PromosTitleSection,
    PopularAside,
    CopiedDiscountCodeDialog,
    DefaultSpace,
    NewsSection,
    FinalChanceSection,
    FolderAnnouncementSection,
    BannerSection,
    NewPromosSection,
    PopularFoldersSection,
    PromosSection,
  },
  mixins: [needAuth, CopiedDiscountCodeDialog],
  computed: {
    ...mapGetters('auth', [
      'LOCALE',
    ]),
  },
  mounted() {
    this.SET_FILTER_ACTIVE('ch_filter');
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_ACTIVE',
      'SET_FILTER_INACTIVE',
      'SET_FILTER_OPENED',
      'RESET_FILTERS',
    ]),
  },
}
</script>
