<template>

  <div class="promos-section">

    <div class="promos-section__container pt-0">

      <TransitionGroup
          tag="div"
          name="fade"
          class=" container promos-grid">

        <div
            v-for="promo in promos"
            :key="promo">

          <component
              :is="getComponentName(promo.morphable_type)"
              :promo="promo.data"
              :promoted-promo="promo.hero_section_promo"
          />

        </div>
      </TransitionGroup>

      <router-localized-link
          :to="{name: 'promos'}"
          class="promos-section__link"
      >

        <span v-t="'landing.promos.subtitle'"/>
        <i class="icon-triangle-arrow ms-2"/>

      </router-localized-link>

    </div>

  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink.vue';
import FolderPromoHorizontalDesktop from '@/components/feed/promo/desktop/FolderPromoHorizontalDesktop.vue';
import OnlinePromoHorizontalDesktop from '@/components/feed/promo/desktop/OnlinePromoHorizontalDesktop.vue';
import PostHorizontalDesktop from '@/components/feed/promo/desktop/PostHorizontalDesktop.vue';
import  constants  from '@/plugins/consts.js';

export default {
  name: 'PromosSection',
  components: {
    PostHorizontalDesktop,
    OnlinePromoHorizontalDesktop,
    FolderPromoHorizontalDesktop,
    RouterLocalizedLink,
  },
  computed: {
    ...mapGetters('promo', [
      'PROMOS',
      'PROMOS_PROMISE',
      'PROMOS_TOTAL',
      'PROMOS_IS_COMPLETE',
    ]),

    ...mapGetters('promoted-promo', [
        'PROMOTED_PROMO'
    ]),
    /**
     * Hero Promo
     * @returns {*}
     */
    heroPromo() {
      return _.get(this.PROMOTED_PROMO, '0', undefined)
    },
    /**
     * Hero Promo
     * @returns {*}
     */
    promos() {
      if (this.heroPromo && this.PROMOS.length)
        return [{...this.heroPromo, hero_section_promo: true}, ...this.PROMOS];
      return this.PROMOS
    },
  },
  data() {
    return {
      fetchOptions: {
        per_page: 9,
        only_valid_promos: 1,
        only_paid_client: 1,
        vanishes_at: 90,
        folders_included: 0,
        order: 'rating_count',
      },
      promotedFetchOptions: {
        section: constants.PROMOTED_PROMOS_SECTIONS.SECTION_HERO,
      },
    }
  },
  mounted() {
    this.RESET_PROMOS();
    this.load()
  },
  methods: {
    ...mapActions('promo', [
      'RESET_PROMOS',
      'FETCH_PROMOS',
    ]),
    ...mapActions('promoted-promo', [
        'FETCH_PROMOTED_PROMO'
    ]),
    ...mapActions('filter', [
      'RESET_FILTERS',
    ]),
    load() {
      this.FETCH_PROMOS(this.fetchOptions);
      this.FETCH_PROMOTED_PROMO(this.promotedFetchOptions);
    },
    getComponentName(morphableType) {
      const componentMap = {
        Post: 'post-horizontal-desktop',
        OnlinePromo: 'online-promo-horizontal-desktop',
        FolderItem: 'folder-promo-horizontal-desktop',
      };
      return componentMap[morphableType];
    },
  }
}
</script>
