<template>
  <div class="promos-section">
    <div class="promos-section__container">
      <h2
          class="promos-section__title"
          v-html="$t('landing.new_promos.title')"/>

      <TransitionGroup
          tag="div"
          name="fade"
          class=" container promos-grid">

        <div
            v-for="promo in PROMOS"
            :key="promo">

          <component
              :is="getComponentName(promo.morphable_type)"
              :promo="promo.data"
          />

        </div>
      </TransitionGroup>

      <router-localized-link
          :to="{name: 'promos'}"
          :params="{tab: 'recently-created'}"
          class="promos-section__link"
      >

        <span v-t="'landing.new_promos.subtitle'"/>
        <i class="icon-triangle-arrow ms-2"/>

      </router-localized-link>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import DefaultSpace from '@/views/DefaultSpace.vue';
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink.vue';
import PostHorizontalDesktop from '@/components/feed/promo/desktop/PostHorizontalDesktop.vue';
import OnlinePromoHorizontalDesktop from '@/components/feed/promo/desktop/OnlinePromoHorizontalDesktop.vue';
import FolderPromoHorizontalDesktop from '@/components/feed/promo/desktop/FolderPromoHorizontalDesktop.vue';

export default {
  name: 'NewPromosSection',
  components: {
    RouterLocalizedLink,
    DefaultSpace,
    PostHorizontalDesktop,
    OnlinePromoHorizontalDesktop,
    FolderPromoHorizontalDesktop
  },
  computed: {
    ...mapGetters('promos-new', [
      'PROMOS',
    ]),
  },
  data() {
    return {
      fetchOptions: {
        per_page: 6,
        only_valid_promos: 1,
        folders_included: 0,
        only_recently_created: 1,
        order: 'published_at',
      },
    }
  },
  mounted() {
    this.RESET_PROMOS()
    this.load()
  },
  methods: {
    ...mapActions('promos-new', [
      'FETCH_PROMOS',
      'RESET_PROMOS',
    ]),
    load() {
      return this.FETCH_PROMOS(this.fetchOptions);
    },
    getComponentName(morphableType) {
      const componentMap = {
        Post: 'post-horizontal-desktop',
        OnlinePromo: 'online-promo-horizontal-desktop',
        FolderItem: 'folder-promo-horizontal-desktop',
      };
      return componentMap[morphableType];
    },
  }
}
</script>
