<template>
  <div>
    <div class="looking-folder" v-if="storeMatchingQuery">
      <div
          v-html="$t('promo.lookingForFolder',
                {store: storeMatchingQuery.translation.title,
                 folderLink: $localizePath($router.resolve({name: 'folders.store', params: {storeSlug: storeMatchingQuery.translation.route} }).href)})">
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'LookingFolder',
  computed: {
    ...mapGetters('store', [
      'STORES',
    ]),
    /**
     * Folder store matched with search query
     * @returns {*}
     */
    storeMatchingQuery() {
      if (!this.$route.query.q) return null;
      return this.STORES.find(store => store.translation.title.toLowerCase() === this.$route.query.q.toLowerCase())
    },
  },
}
</script>
<style scoped>

</style>
