<template>
  <el-config-provider :locale="elementLocale">
    <div v-loading.fullscreen.lock="IS_LOADING" :key="LOCALE">
      <meta-head></meta-head>
      <main-header></main-header>

      <main>
        <article>
          <router-view v-slot="{ Component }" :key="routeReloadKey">
            <transition
                enter-from-class="fade-enter-from"
                leave-to-class="fade-leave-to"
                enter-active-class="fade-enter-active"
                leave-active-class="fade-leave-active">
              <component :is="Component"/>
            </transition>
          </router-view>
        </article>
      </main>

      <email-subscription-section/>
      <main-footer></main-footer>
      <scroll-top></scroll-top>
    </div>
  </el-config-provider>
</template>

<script>
import {mapGetters} from 'vuex';
import MainHeader from '@/components/header/MainHeader';
import MainFooter from '@/components/footer/MainFooter';
import MetaHead from '@/components/header/MetaHead';
import {ElConfigProvider} from 'element-plus';
import nl from 'element-plus/es/locale/lang/nl';
import fr from 'element-plus/es/locale/lang/fr';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import nlLocaleDayJs from 'dayjs/locale/nl';
import frLocaleDayJs from 'dayjs/locale/fr';

export default {
  name: 'app',
  components: {ElConfigProvider, MetaHead, MainFooter, MainHeader},
  data() {
    return {
      observer: null
    }
  },
  computed: {
    ...mapGetters('loader', [
      'IS_LOADING',
      'ROUTE_RELOAD_KEY',
    ]),

    ...mapGetters('auth', [
      'LOCALE'
    ]),

    elementLocale() {
      return this.LOCALE === 'fr' ? fr : nl
    },
    dayJsLocale() {
      return this.LOCALE === 'fr' ? frLocaleDayJs : nlLocaleDayJs
    },
    routeReloadKey() {
      return this.ROUTE_RELOAD_KEY + this.$route.path;
    }
  },
  beforeMount() {
    this.$store.dispatch('loader/SET_ROUTER_ACTIVE', true);
    this.$store.dispatch('auth/UPDATE_I18N', this.$i18n);

    dayjs.locale(this.dayJsLocale);
    dayjs.extend(updateLocale);
    dayjs.updateLocale(this.LOCALE, {
      weekStart: 1,
    });
  },
  mounted() {
    this.applyButtonStyles();
    this.observeDOMChanges();
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  watch: {
    $i18n: {
      handler(val) {
        this.$store.dispatch('auth/UPDATE_I18N', this.$i18n);
      },
      deep: true
    }
  },
  methods: {
    applyButtonStyles() {
      const A_B_Test = localStorage.getItem('AB_Test_01');
      if (A_B_Test === 'A') {
        const buttons = document.querySelectorAll('.look-promo');
        buttons.forEach(button => {
          button.style.backgroundColor = '#c4060c';
          button.style.border = '0.5px solid #c4060c';
        });
      }
    },
    observeDOMChanges() {
      this.observer = new MutationObserver(() => {
        this.applyButtonStyles();
      });

      this.observer.observe(document.body, {childList: true, subtree: true});
    }

  },
}
</script>
