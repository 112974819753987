<template>
  <div class="centered my-2.25" v-if="total > 0">
    <div>
      <span class="color-text-primary cursor-pointer text-decoration-underline" @click="showMore"
            v-if="hiddenTotal > 0">
        Meer berichten lezen
      </span>
    </div>
  </div>
</template>

<script>
import {needAuth} from '@/mixins/auth/needAuth';

export default {
  name: 'show-more-comments',
  mixins: [needAuth],
  emits: ['show-more'],
  props: {
    total: {
      type: Number,
      default: 0,
    },
    totalVisible: {
      type: Boolean,
      default: true,
    },
    hiddenTotal: {
      type: Number,
      default: 0,
    },
    guestPopup: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    showMore() {
      this.webshopLoginDialog();
      if (this.triggerLoginDialog(this.guestPopup)) {
        return;
      }
      this.$emit('show-more');
    }
  }
}
</script>
