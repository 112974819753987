<template>
  <horizontal-promo-layout
    :promo="promo"
    :avatar="authorAvatar"
    :name="authorName"
    :profile-id="profileId"
    :profileType="profileType"
    :promoted-promo="promotedPromo"
    morphable_type="FolderItem"
    @like="makeHit"
    @favourite="makeFavourite"
  >
    <template #image>
      <div class="w-100 h-100" v-loading="imageIsLoading">
        <img
            class="lazy-load"
          v-lazy="promo.image_url"
          :data-src="promo.image_url"
          @click="showFullPromo"
          @load="imageIsLoading = false"
          alt=""
        >
      </div>
    </template>

    <template #description>
      <div class="d-flex flex-column justify-content-center h-100" v-visible="fireViewEvent">
        <div class="d-flex flex-column">
          <span class="title " v-text="promo.title" @click="showFullPromo"/>

          <div class="d-flex align-items-md-center align-items-start flex-md-row flex-column "  :class="[xsOnly? 'mb-1': 'mb-2']">
            <div class="price" v-if="promo.new_price || promo.discount">
              <template v-if="promo.new_price">
                <template v-if="promo.old_price">
                  <span class="price--old">€ {{ promo.old_price }}</span>
                  <span class="price--separator"> | </span>
                </template>
                <span class="price--new">€ {{ promo.new_price }}</span>
              </template>

              <span v-else-if="promo.discount" class="price--discount">
              {{ $t('post.discount') }}: {{ promo.discount }}
            </span>

            </div>
            <div class="d-flex justify-content-between align-items-center w-100 mt-1">
            <folder-promo-page-label  :folder-page="promo.folderPage"/>
              <div v-if="xsOnly" class="expiration-date">
                <span v-if="promo.is_expired" class="expired-promo">
                  {{ $t('post.expired.expired') }}
                </span>
                <span v-else-if="promo.valid_to_formatted">
                  {{ $t('promo.expires_at', {date: promo.valid_to_formatted}) }}
                </span>
              </div>
            </div>
          </div>

          <div>
            <button class="more" @click="showFullPromo">{{ $t('post.meer_detail') }}</button>
          </div>
        </div>

        <button class="look-promo" @click="showFullPromo">
          {{ $t('post.look_post') }}
        </button>

      </div>
    </template>
  </horizontal-promo-layout>
</template>

<script>
import {needAuth} from '@/mixins/auth/needAuth';
import {actions} from '@/mixins/post/actions';
import HorizontalPromoLayout from '@/components/feed/promo/desktop/HorizontalPromoLayout.vue';

export default {
  name: 'folder-promo-horizontal',
  components: {HorizontalPromoLayout},
  mixins: [actions, needAuth],
  props: {
    promo: Object,
    isBangPromo: {
      type: Boolean,
      default() {
        return false
      }
    },
    vuexModule: {
      type: String,
      default: 'promo',
    },
  promotedPromo: {
    type: Boolean,
    default: false,
  },
  },
  data() {
    return {
      imageLoadError: false,
      imageIsLoading: true,
    }
  },
  computed: {
    /**
     * @returns {any}
     */
    editor() {
      return this.promo.editor;
    },
    /**
     * Avatar url
     * @returns {*}
     */
    authorAvatar() {
      return !this.editor ? this.promo.folderPage.folder.store.image_url : this.editor.avatar_url
    },
    /**
     * Store title
     * @returns {*|string}
     */
    storeTitle() {
      const folderPage = this.promo.folderPage;

      return folderPage ? folderPage.folder.store.translation.title : ''
    },
    /**
     * Author name
     * @returns {*|string}
     */
    authorName() {
      return !this.editor ? this.storeTitle : this.editor.full_name
    },
    profileId() {
      return !this.editor ? this.promo.folderPage.folder.store.translation.route : String(this.editor.id)
    },
    profileType() {
      return !this.editor ? 'store' : 'user'
    },
    /**
     * Data for mixin
     * @returns {{morphable_type: string, entity: Object}}
     */
    entityData() {
      return {
        entity: this.promo,
        morphable_type: 'FolderItem'
      }
    },
  },
  mounted() {
    this.emitter.on('show-folder-promo-preview', (id) => {
      if (this.promo.id === id) {
        this.showFullPromo();
      }
    });
  },
  methods: {
    showFullPromo() {
      this.$emit('opened')
      this.routerPush({name: 'folder_promos.show', params: {id: String(this.promo.id)}});
    },

    /**
     * Favourite
     */
    makeFavourite() {
      this.processFavourite()?.then(()=>{
        this.$emit('favorite');
      })
    },
    /**
     * Like
     */
    makeHit() {
      const isHit = this.promo.is_hit;
      const thisElem = this

      this.processHit()?.then(() => {
        thisElem.$emit('voted')
        if (!isHit) {
          this.processEventHit(this.prepareFolderPromoEventData(this.promo));
        }
      })
    },
    fireViewEvent() {
      this.processEventView(this.prepareFolderPromoEventData(this.promo));
    }
  }
}
</script>
