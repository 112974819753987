<template>
  <default-grid :show-right-container="false">
    <template v-slot:left-container>
      <left-bar show-product-logo show-store-mailer :show-filters-list="false" type="PRODUCT"></left-bar>
    </template>
    <template v-slot:main-container>
      <div class="promos-feed">
        <div class="d-flex align-items-start gap-2 header-container">
          <h1 class="promos-feed-title font-weight-medium" v-html="h1Text"></h1>
          <button  class="look-subscription d-md-none" @click="subscriptionVisible =! subscriptionVisible"  >
            <i v-if="CURRENT_PRODUCT.subscription" class="icon-bell-3"/>
            <i v-else class="icon-bell"/>
          </button>
        </div>

        <tabs
          ref="tabs_component"
          :tabs="tabs"
          :default="currentTab"
          @change="tabChange"
          change-query
        />
          <div class="mt-3 mt-md-0">
            <Transition>
              <div v-show="subscriptionVisible" class="store-mailer-mobile__wrapper">
                <subscription  type="PRODUCT" :dividers="false"></subscription>
              </div>
            </Transition>
            <search-promos-list
              :items="PROMOS"
              :current-tab="currentTab"
              :vuex-modules="{'post': 'promo', 'online_promo': 'promo', 'folder_promo': 'promo'}"
            />

            <infinite-loading @infinite="loadMore" :key="currentTab + infiniteKey" :distance="2000">
              <template #spinner>
                <div class="spinner-container" v-loading="true"/>
              </template>
              <template #complete>
                <div class="color-primary font-size-18 my-3">
                  <div v-if="!$store.getters['auth/GUEST']">
                    <span v-t="'search.loading.no_results'" v-if="PROMOS_TOTAL < 1"/>
                  </div>
                </div>
              </template>
              <template #error>
                <div></div>
              </template>
            </infinite-loading>
          </div>
      </div>
    </template>
  </default-grid>
</template>

<script>
import DefaultGrid from '@/views/DefaultGrid';
import LeftBar from '@/components/left_bar/LeftBar';
import Tabs from '@/components/feed/Tabs';
import {mapGetters} from 'vuex';
import ProductRoute from '@/router/guards/ProductRoute';
import SearchPromosList from '@/components/feed/SearchPromosList';
import {store_webshop_brand_product} from '@/mixins/group/store_webshop_brand_product';
import InfiniteLoading from 'v3-infinite-loading';
import {needAuth} from '@/mixins/auth/needAuth';
import Subscription from '@/components/left_bar/store/Subscription.vue';

export default {
  name: 'ProductView',
  components: {
    InfiniteLoading,
    SearchPromosList,
    Tabs,
    LeftBar,
    DefaultGrid,
    Subscription
  },
  mixins: [store_webshop_brand_product, needAuth],
  data() {
    return {
      subscriptionVisible: false,
      currentTab: _.get(this.$route.query, 'tab', 'default'),
      fetchOptions: {
        type_kind_not: this.$consts.KIND.QUESTION,
        include_filters: ['types'],
        types: [],

        q: '',
        per_page: 12,
      },
      infiniteKey: 1
    }
  },
  computed: {
    ...mapGetters('product', [
      'CURRENT_PRODUCT',
    ]),
    /**
     * Tabs
     * @returns {{}}
     */
    tabs() {
      const tabs = {};
      tabs['default'] = this.$t('product.tabs.default');
      tabs['folder-promos'] = this.$t('product.tabs.folder-promos');
      tabs['online-promos'] = this.$t('product.tabs.online-promos');
      tabs['posts'] = this.$t('product.tabs.posts');
      return tabs;
    },
    /**
     * Title text
     * @returns {*}
     */
    h1Text() {
      const date = new Date();
      const m = date.toLocaleString(this.$i18n.locale, {month: 'long'});
      const y = date.getFullYear();
      return this.$t('product.product_page_h1', {product: this.CURRENT_PRODUCT.translation.title, month: m, year: y});
    }
  },
  // need this because Guards doesnt work when the same route params changing
  async beforeRouteUpdate(to, from) {
    if (from.params.productSlug !== to.params.productSlug) {
      await ProductRoute(to);
    }
  },
  methods: {
    loadMore(state) {
      if (this.PROMOS.length && this.triggerLoginDialog()) {
        state.complete();
        return;
      }

      this.loadResults().then(() => {
        if (this.PROMOS_IS_COMPLETE) {
          state.complete();
        } else {
          state.loaded();
        }
      })
    },
    prepareFetchOptions() {
      this.SET_FILTER_ACTIVE('types_filter');

      this.fetchOptions.product = this.CURRENT_PRODUCT.id;
      this.fetchOptions.types = this.FILTERS_VALUES.types_filter;
      this.fetchOptions.order = 'codes_first';

      switch (this.currentTab) {
        case 'online-promos':
          this.SET_FILTER_INACTIVE('types_filter');
          this.fetchOptions.types = [];
          this.fetchOptions.morphable_types = ['OnlinePromo'];
          break;
        case 'folder-promos':
          this.SET_FILTER_INACTIVE('types_filter');
          this.fetchOptions.types = [];
          this.fetchOptions.morphable_types = ['FolderItem'];
          break;
        case 'posts':
          this.fetchOptions.morphable_types = ['Post'];
          break;
        case 'default':
        default:
          this.fetchOptions.morphable_types = [];
          break;
      }
    },
  }
}
</script>
