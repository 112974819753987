export const gtm_events = {
    methods: {
        processEventClick(eventDataToPush) {
            window.dataLayer?.push(eventDataToPush);
            if (_.has(eventDataToPush, 'post_id')) {
                console.log('post click event');
                axios.put(`/posts/${eventDataToPush.post_id}/add-click`);
            }
            if (_.has(eventDataToPush, 'folder_item_id')) {
                console.log('folder click event');
                axios.put(`/folder-items/${eventDataToPush.folder_item_id}/add-click`);
            }
            if (_.has(eventDataToPush, 'online_promo_id')) {
                console.log('online promo click event');
                axios.put(`/online-promos/${eventDataToPush.online_promo_id}/add-click`);
            }
        },
        processEventView(eventDataToPush) {
            const eventType = this.getEventTypeForEvent();
            if (!eventType) {
                console.log('Route not found for promoview event');
                return;
            }

            eventDataToPush['event'] = 'promoview';
            eventDataToPush['event_type'] = eventType;
            eventDataToPush['view_on_link'] = window.location.href;

            window.dataLayer?.push(eventDataToPush);
            if (_.has(eventDataToPush, 'post_id')) {
                console.log('post view event');
            }
            if (_.has(eventDataToPush, 'folder_item_id')) {
                console.log('folder view event');
            }
            if (_.has(eventDataToPush, 'online_promo_id')) {
                console.log('online promo view event');
            }
        },
        processEventHit(eventDataToPush) {
            const eventType = this.getEventTypeForEvent();
            if (!eventType) {
                console.log('Route not found for hit event');
                return;
            }

            eventDataToPush['event'] = 'vote';
            eventDataToPush['event_type'] = eventType;

            window.dataLayer?.push(eventDataToPush);
            if (_.has(eventDataToPush, 'post_id')) {
                console.log('post hit event');
            }
            if (_.has(eventDataToPush, 'folder_item_id')) {
                console.log('folder hit event');
            }
            if (_.has(eventDataToPush, 'online_promo_id')) {
                console.log('online promo hit event');
            }
        },
        getEventTypeForEvent() {
            const routeToEventTypeMap = {
                'home': 'promoview_hp',
                // 'promos': 'promoview_category', (promoview_search)
                'favourites': 'promoview_favourites',

                'brand.show': 'promoview_brand',
                'product.show': 'promoview_product',
                'webshop.show': 'promoview_webshop',
                'store.show': 'promoview_store',

                'folder_promos.show': 'promoview_detailpage',
                'online_promos.show': 'promoview_detailpage',
                'posts.show': 'promoview_detailpage',
            };

            // specific case. It can be search or categories
            if (this.$route.name === 'promos') {
                if (!_.isEmpty(this.$route.query.q)) {
                    return 'promoview_search';
                }
                // this.$route.params.categorySlug
                return 'promoview_category';
            }

            if (_.has(routeToEventTypeMap, this.$route.name)) {
                return routeToEventTypeMap[this.$route.name];
            }
            return false;
        },
        preparePostEventData(post) {
            return {
                post_id: post.id,
                post_title: post.title,
                webshop_id: _.get(post, 'webshops[0].id', null),
                webshop_title: _.get(post, 'webshops[0].translation.title', null),
                store_id: _.get(post, 'stores[0].id', null),
                store_title: _.get(post, 'stores[0].translation.title', null),
                author_id: _.get(post, 'user.id'),
                author_name: _.get(post, 'user.full_name'),
            };
        },
        prepareOnlinePromoEventData(onlinePromo) {
            return {
                online_promo_id: onlinePromo.id,
                online_promo_title: onlinePromo.title,
                webshop_id: onlinePromo.webshop.id,
                webshop_title: onlinePromo.webshop.translation.title,
            };
        },
        prepareFolderPromoEventData(folderPromo) {
            return {
                folder_item_id: folderPromo.id,
                folder_item_title: folderPromo.title,
                store_id: folderPromo.folderPage.folder.store.id,
                store_title: folderPromo.folderPage.folder.store.translation.title,
            };
        }
    },
};
