import store from '@/store';
import debounce from 'lodash.debounce'

export const autocompelete = {
    data() {
        return {
            presuggestions: [],
            suggestions: [],
            popperFocused: false,
            keywords_to_ignore_when_search: {
                folder: ['folder', 'folders', 'reclamefolder', 'depliant', 'depliants', 'déplians', 'dépliants', 'dépliant']
            },
            suggestionsDebounce: debounce((text) => {
                this.getSuggestions(text);
            }, 300)
        };
    },
    mounted() {
        this.$refs.autocomplete.inputRef.$el.addEventListener('input', (text) => {
            const query = text.target.value;
            this.$refs.autocomplete.focus();
            this.query = query;
        });
    },
    methods: {
        getSuggestions(queryString) {
            const thisElem = this;
            if (_.get(queryString, 'length', 0) < 1) {
                this.suggestions = [];
                return Promise.resolve();
            }
            const groupsPriority = {
                folder: 1,
                store: 2,
                webshop: 3,
                product: 4,
                brand: 5,
                // article: 6,
            };

            return axios.post('/search/suggest', {search: queryString}).then((data) => {
                const result = [];

                _.forEach(_.keys(data.data), function (groupKey) {

                    if (groupKey === 'article' && _.get(data.data[groupKey], 'length') > 0) {
                        result.push({type: 'space'})
                        result.push({
                            title: thisElem.$t('news.news_title'),
                            type: 'header'
                        })
                    }

                    _.forEach(data.data[groupKey], function (item) {
                        result.push({
                            groupKey: groupKey,
                            title: item.title,
                            entity_id: item.id,
                            url: item.url,
                            active_folders: item.active_folders,
                            group: thisElem.$t('search.autosuggest.types.' + groupKey),
                            priority: _.get(groupsPriority, groupKey, undefined),
                        });
                    });
                });
                this.suggestions = result;
            });
        },
        querySearch(queryString, cb) {
            let suggestions = [];

            const queryLength = _.get(queryString, 'length', 0);
            if (queryLength === 0) {
                cb(this.presuggestions);
                return;
            }

            if (queryLength > 0) {
                suggestions = _.sortBy(this.suggestions, function (need) {
                    return need.priority;
                });
            }

            cb(suggestions);
        },
        triggerSuggest(query) {
            const thisElem = this;
            let triggered = false;

            _.forEach(thisElem.keywords_to_ignore_when_search, function (keywords, groupKey) {
                const keywordFound = keywords.some(word => query.includes(word));
                const suggest = thisElem.suggestions.find(item => item.groupKey === groupKey);

                if (suggest && keywordFound) {
                    thisElem.$router.push(suggest.url);
                    triggered = true;
                    return false; // break foreach
                }
            });

            return triggered;
        },
        focusOut(secs = 0) {
            setTimeout(() => this.popperFocused = false, secs)
        },
        handleFocus() {
            const thisElem = this;

            return axios.get('/search/before-search')
                .then((data) => {
                    thisElem.popperFocused = true;
                    const result = [];

                    _.forEach(data.data, function (item, key) {
                        if (!data.data[key].length) {
                            return;
                        }

                        /**
                         * Option categories
                         */
                        result.push({
                            title: thisElem.$t('search.autosuggest.categories.' + key),
                            type: 'header'
                        })

                        /**
                         * Options
                         */
                        _.forEach(data.data[key], function (item) {
                            result.push({
                                groupKey: item.group,
                                title: item.title,
                                url: item.url,
                                entity_id: item.recentable_id,
                                group: thisElem.$t('search.autosuggest.types.' + item.group),
                                icon: key === 'popular' ? 'icon-starpopular' : false
                            });
                        });

                        /**
                         * Empty spaces
                         */
                        if (key !== 'popular') {
                            result.push({type: 'space'})
                        }
                    });

                    this.presuggestions = result;
                });
        },
        saveSearchTerm(item) {
            return axios.post('/search/search-term', {
                url: item.url,
                query: item.query ?? item.title,
                group: item.groupKey,
                entity_id: item.entity_id,
            })
        },
        handleSelect(item) {
            if (store.getters['auth/GUEST']) {
                window.open(item.url, '_self');
                return;
            }

            this.saveSearchTerm(item).then(r => this.$router.push(item.url))
        },
    },
    watch: {
        query(newQuery, oldQuery) {
            this.suggestionsDebounce(newQuery);
        },
    }
};
