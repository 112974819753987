export default {
    namespaced: true,
    state: {
        categories: {
            all: [],
            promise: null
        },
        current_category: {
            data: {},
            promise: null,
        },
        popular_categories: {
            all: [],
            promise: null,
        },
    },

    getters: {
        CATEGORIES(state) {
            return state.categories.all;
        },
        CURRENT_CATEGORY(state) {
            return state.current_category.data;
        },
        POPULAR_CATEGORIES(state) {
            return state.popular_categories.all;
        }
    },

    actions: {
        async FETCH_CATEGORIES({state, commit}) {
            if (state.categories.promise) {
                return state.categories.promise;
            }

            const promise = axios.get('/categories').then((response) => {
                commit('UPDATE_CATEGORIES', response.data.data);
            }).catch((e) => {
                console.log(e);
            }).then(() => {
                commit('UPDATE_PROMISE_CATEGORIES', null);
            });

            commit('UPDATE_PROMISE_CATEGORIES', promise);
            return promise;
        },

        FETCH_CURRENT_CATEGORY({state, commit, dispatch}, slug) {
            if (state.current_category.promise) {
                return state.current_category.promise;
            }

            const promise = axios.get('/category/' + slug).then((response) => {
                commit('UPDATE_CURRENT_CATEGORY',  _.get(response,'data.data', {}));
            }).catch((e) => {
                console.log(e);
            }).then(() => {
                commit('UPDATE_PROMISE_CURRENT_CATEGORY', null);
            });

            commit('UPDATE_PROMISE_CURRENT_CATEGORY', promise);
            return promise;
        },

        RESET_CURRENT_CATEGORY({state, commit, dispatch}) {
            commit('UPDATE_CURRENT_CATEGORY', null);
        },
        async FETCH_POPULAR_CATEGORIES({state, commit}) {
            if (state.popular_categories.promise) {
                return state.popular_categories.promise;
            }

            const promise =  axios.get('/categories/popular').then((response) => {
                commit('UPDATE_POPULAR_CATEGORIES', response.data.data);
            }).catch((e) => {
                console.log(e);
            }).then(() => {
                commit('UPDATE_PROMISE_POPULAR_CATEGORIES', null);
            });

            commit('UPDATE_PROMISE_POPULAR_CATEGORIES', promise);

            return promise
        },
    },

    mutations: {
        UPDATE_CATEGORIES(state, data) {
            return state.categories.all = data;
        },

        UPDATE_PROMISE_CATEGORIES(state, data) {
            return state.categories.promise = data;
        },

        UPDATE_CURRENT_CATEGORY(state, data) {
            return state.current_category.data = data;
        },

        UPDATE_PROMISE_CURRENT_CATEGORY(state, data) {
            return state.current_category.promise = data;
        },

        UPDATE_POPULAR_CATEGORIES(state, data) {
            return state.popular_categories.all = data;
        },

        UPDATE_PROMISE_POPULAR_CATEGORIES(state, data) {
            return state.popular_categories.promise = data;
        },
    }
}
