<template>
  <el-row justify="center" class="profile-page" v-if="profileLoaded">
    <el-col :span="23">
      <div class="container">
        <div class="main-container-wrapper">


          <el-row type="flex" justify="space-between">
            <el-col :span="24" :lg="17" :xl="17">

              <el-row type="flex">
                <el-col :span="24">
                  <div class="main-inner-block">
                    <div class="account-block">
                      <div class="account-part">
                        <div class="upper-part">
                          <div class="account-header">
                            <div class="avatar-block">
                              <div class="inner-wrapper">
                                <el-avatar :src="PROFILE.avatar_url" shape="circle"
                                           class="profile-user-avatar">
                                </el-avatar>
                              </div>
                            </div>

                            <div class="social-block">
                              <a v-if="PROFILE.metadata.facebook" target="_blank"
                                 :href="PROFILE.metadata.facebook" class="facebook-link">
                                <i class="icon-Facebook1 font-weight-bold"></i>
                              </a>

                              <a v-if="PROFILE.metadata.instagram" target="_blank"
                                 :href="PROFILE.metadata.instagram" class="instagram-link">
                                <i class="icon-Instagram font-weight-bold"></i>
                              </a>
                            </div>

                            <div class="side-bar-item-title font-weight-medium">{{ PROFILE.full_name }}</div>

                            <div class="tags-block">
                              <span v-if="PROFILE.metadata.profile_title">
                                {{ PROFILE.metadata.profile_title }}
                              </span>
                              <span v-else>
                              </span>
                            </div>

                          </div>
                          <profile-banner
                              :this-is-my-profile="thisIsMyProfile"
                              @banner-changed="loadProfile"
                          ></profile-banner>
                        </div>

                        <el-row type="flex" class="account-footer" justify="left">
                          <el-col :span="24" :sm="24" :lg="10" class="profile-actions">

                            <el-button v-if="!PROFILE.is_following && thisIsMyProfile" :type="thisIsMyProfile ? 'danger' : 'primary'"
                                       :plain="thisIsMyProfile"
                                       :disabled="thisIsMyProfile || GUEST"
                                       class="d-block action-button action-button-my-profile"
                                       @click="followUser">
                              <i class="icon-bell"></i>&nbsp;
                              <span v-t="'profile.follow_btn'"></span>
                            </el-button>

                            <el-button v-else-if="!PROFILE.is_following && !thisIsMyProfile" :type="thisIsMyProfile ? 'danger' : 'primary'"
                                       :plain="thisIsMyProfile"
                                       :disabled="thisIsMyProfile || GUEST"
                                         class="d-block action-button action-button-follow-btn"
                                       @click="followUser">
                              <i class="icon-bell"></i>&nbsp;
                              <span v-t="'profile.follow_btn'"></span>
                            </el-button>

                            <el-button v-else type="primary-border"
                                       plain
                                       class="d-block action-button action-button-unfollow-btn"
                                       @click="unfollowUser">
                              <i class="icon-bell-3"></i>&nbsp;
                              <span v-t="'profile.unfollow_btn'"></span>
                            </el-button>

                          </el-col>
                          <el-col :span="24" :sm="24" :lg="14">
                            <div class="achievements-list">
                              <div class="achievement-item">
                                <div class="image-wrapper">
                                  <div class="achievement-icon">
                                    <i class="icon-star"></i>
                                  </div>
                                </div>
                                <div class="break"></div>
                                <div class="text-block">
                                  <div class="title" v-t="'profile.achievements.rank'"></div>
                                  <div class="number">
                                    <span v-if="PROFILE.rank > 0">
                                      #{{ PROFILE.rank }}
                                    </span>
                                    <span v-else>
                                      /
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="achievement-item">
                                <div class="image-wrapper">
                                  <div class="achievement-icon">
                                    <i class="icon-coconut"></i>
                                  </div>
                                </div>
                                <div class="break"></div>
                                <div class="text-block">
                                  <div class="title" v-t="'profile.achievements.followers_count'"></div>
                                  <div class="number">{{ PROFILE.followers_count }}</div>
                                </div>
                              </div>
                              <div class="achievement-item">
                                <div class="image-wrapper">
                                  <div class="achievement-icon">
                                    <i class="icon-island"></i>
                                  </div>
                                </div>
                                <div class="break"></div>
                                <div class="text-block">
                                  <div class="title" v-t="'profile.achievements.member_since'"></div>
                                  <div class="number">{{ PROFILE.member_since }}</div>
                                </div>
                              </div>
                            </div>
                          </el-col>
                        </el-row>

                        <el-row class="m-3" v-if="xsOnly">
                          <el-col :span="24">
                            <div class="filter-header side-bar-item-title mb-3"
                                 @click="moreFactsActive = !moreFactsActive">
                              <i :class="moreFactsActive ? 'icon-minus' : 'icon-plus-1'"></i>&nbsp;
                              <span class="filter-header__text" v-t="'profile.more_facts_btn'"></span>
                            </div>
                            <div class="filter-options" v-if="moreFactsActive">
                              <profile-stats></profile-stats>
                            </div>
                          </el-col>
                        </el-row>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>

              <el-row class="mt-3" type="flex">
                <el-col :span="24" :lg="8" v-if="lgAndUp">
                  <filters-list></filters-list>
                </el-col>
                <el-col :span="24" :lg="16">
                  <div class="feed-container mt-3">
                    <div class="home-feed">
                      <TransitionGroup tag="div" name="fade" class=" container promos-grid">
                        <div v-for="(promo, i) in PROMOS" :key="promo">
                          <template v-if="'FolderItem' === promo.morphable_type">
                            <folder-promo-horizontal-desktop
                                :promo="promo.data"
                                @opened="saveScrollPosition(i)"
                                @voted="sortPromos"
                            />
                          </template>

                          <template v-if="'OnlinePromo' === promo.morphable_type">
                            <online-promo-horizontal-desktop
                                :promo="promo.data"
                                @opened="saveScrollPosition(i)"
                                @voted="sortPromos"
                            />
                          </template>

                          <template v-if="'Post' === promo.morphable_type">
                            <post-horizontal-desktop
                                :promo="promo.data"
                                @opened="saveScrollPosition(i)"
                                @voted="sortPromos"
                            />
                          </template>
                        </div>
                      </TransitionGroup>
                      <infinite-loading @infinite="load" :key="currentTab" :distance="2000">
                        <template #spinner>
                          <div class="spinner-container" v-loading="true"></div>
                        </template>
                        <template #complete>
                          <div></div>
                        </template>
                        <template #error>
                          <div></div>
                        </template>
                      </infinite-loading>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </el-col>

            <el-col :span="24" :lg="6" :xl="6" v-if="lgAndUp">
              <profile-stats></profile-stats>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>

import {mapActions, mapGetters} from 'vuex';
import InfiniteLoading from 'v3-infinite-loading';
import FiltersList from '@/components/left_bar/filters/FiltersList';
import ProfileStats from '@/components/feed/profile/ProfileStats';
import ProfileBanner from '@/components/feed/profile/ProfileBanner';
import OnlinePromoHorizontalDesktop from '@/components/feed/promo/desktop/OnlinePromoHorizontalDesktop.vue';
import FolderPromoHorizontalDesktop from '@/components/feed/promo/desktop/FolderPromoHorizontalDesktop.vue';
import PostHorizontalDesktop from '@/components/feed/promo/desktop/PostHorizontalDesktop.vue';
import {breakpoint} from '@/mixins/public_spa_js/breakpoint';

export default {
  name: 'ProfileView',
  components: {
    PostHorizontalDesktop, FolderPromoHorizontalDesktop, OnlinePromoHorizontalDesktop,
    ProfileBanner,
    ProfileStats,
    FiltersList,
    InfiniteLoading
  },
  data() {
    return {
      infiniteKey: 1,
      currentTab: 'default',
      profileId: this.$route.params.userId,
      followersActive: true,
      searchActive: true,
      filterActive: true,
      categoryActive: false,
      shopActive: false,
      moreFactsActive: false,
      fetchOptions: {
        include_filters: ['types', 'categories', 'stores'],
        morphable_types: ['Post'],
        types: [],
        categories: [],
        stores: [],
        order: '',
        only_favourites: '',
      }
    }
  },
  computed: {
    ...mapGetters('auth', [
      'USER',
      'GUEST',
    ]),
    ...mapGetters('profile', [
      'PROFILE',
      'FOLLOWERS',
    ]),
    ...mapGetters('promo', [
      'PROMOS',
      'PROMOS_TOTAL',
      'PROMOS_IS_COMPLETE',
    ]),
    ...mapGetters('filter', [
      'FILTERS_VALUES',
    ]),

    profileLoaded() {
      return _.get(this.PROFILE, 'id', false);
    },

    thisIsMyProfile() {
      return _.get(this.PROFILE, 'id', -1) === _.get(this.USER, 'id', -2)
    }
  },
  beforeMount() {
    this.RESET_PROMOS();
    this.RESET_FILTERS();

    this.loadProfile();
    this.prepareFetchOptions();
    this.loadPromos();
  },
  methods: {
    ...mapActions('profile', [
      'FETCH_PROFILE',
      'FOLLOW_PROFILE',
      'UNFOLLOW_PROFILE',
    ]),
    ...mapActions('filter', [
      'SET_FILTER_ACTIVE',
      'SET_FILTER_INACTIVE',
      'RESET_FILTERS',
    ]),
    ...mapActions('promo', [
      'FETCH_PROMOS',
      'RESET_PROMOS',
    ]),
    followUser() {
      this.FOLLOW_PROFILE(this.profileId);
    },
    unfollowUser() {
      this.UNFOLLOW_PROFILE(this.profileId);
    },

    prepareFetchOptions() {
      this.SET_FILTER_ACTIVE('types_filter');
      this.SET_FILTER_ACTIVE('stores_filter');

      this.fetchOptions.categories = this.FILTERS_VALUES.categories_filter;
      this.fetchOptions.stores = this.FILTERS_VALUES.stores_filter;
      this.fetchOptions.types = this.FILTERS_VALUES.types_filter;
      this.fetchOptions.user_id = this.profileId;
    },

    loadPromos() {
      return this.FETCH_PROMOS(this.fetchOptions);
    },

    load(state) {
      this.loadPromos().then(() => {
        this.infiniteKey++;
        if (this.PROMOS_IS_COMPLETE) {
          state.complete();
        } else {
          state.loaded();
        }
      });
    },

    loadProfile() {
      return this.FETCH_PROFILE({userId: this.profileId});
    },

    saveScrollPosition(key) {
      breakpoint.set(this.$router.currentRoute.value.fullPath, {pos: window.scrollY, key})
    },

    /**
     * Sort Promos
     * @returns {*}
     */
    sortPromos() {
      this.promos = this.promos.sort(function (a, b) {
        return b.data.rating_calculated - a.data.rating_calculated
      })
    },
  },
  watch: {
    FILTERS_VALUES: {
      // the callback will be called immediately after the start of the observation
      deep: true,
      handler(newVal, oldVal) {
        this.RESET_PROMOS();
        this.prepareFetchOptions();
        this.loadPromos().then(() => {
          this.infiniteKey++;
        });
      }
    },
  },
  async beforeRouteUpdate(to, from) {
    this.profileId = to.params.userId
    this.loadProfile();
  },
}
</script>
