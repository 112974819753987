<template>
  <popular-card
      class="mb-2"
      :title="$t('landing.popular_categories.title')"
      :items="POPULAR_CATEGORIES"
      title-key="translation.simplified_title"
      show-view-all
      :view-all-route="{name: 'promos'}"
      :view-all-text="$t('footer.title')"
      @view-single="gotoCategory"
  />
  <popular-card
      class="mb-2"
      :title="$t('landing.popular_folders.new_title')"
      :items="STORES_WITH_LATEST_FOLDERS"
      title-key="translation.title"
      :view-all-text="$t('landing.popular_folders.subtitle')"
      show-view-all
      :view-all-route="{name: 'folders'}"
      @view-single="gotoFolder"
  />

  <popular-card
      :title="$t('landing.popular_kortingscode.title')"
      :items="LATEST_DISCOUNT_WEBSHOPS"
      title-key="translation.title"
      :view-all-text="$t('landing.popular_kortingscode.subtitle')"
      show-view-all
      :view-all-route="{name: 'promos', query: {'promos_filter[]': 'discount_code'}}"
      @view-single="gotoWebshop"
  />
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import PopularCard from '@/components/landing/PopularCard.vue';

export default {
  name: 'PopularAside',
  components: {
    PopularCard,
  },
  computed: {
    ...mapGetters('category', ['POPULAR_CATEGORIES']),
    ...mapGetters('store', [
      'STORES_WITH_LATEST_FOLDERS'
    ]),
    ...mapGetters('webshop', [
      'LATEST_DISCOUNT_WEBSHOPS'
    ]),

  },
  data() {
    return {

    }
  },
  mounted() {
    this.load()
  },
  methods: {
    ...mapActions('store', [
      'FETCH_LATEST_FOLDER_STORES',
    ]),
    ...mapActions('webshop', [
      'FETCH_LATEST_DISCOUNT_WEBSHOPS',
    ]),
    ...mapActions('category', [
      'FETCH_POPULAR_CATEGORIES',
    ]),
    load() {
      this.FETCH_POPULAR_CATEGORIES();
      this.FETCH_LATEST_FOLDER_STORES();
      this.FETCH_LATEST_DISCOUNT_WEBSHOPS();
    },

    gotoCategory(categorySlug){
      this.routerPush({name: 'promos', params: {categorySlug: categorySlug}})
    },
    gotoFolder(storeSlug){
      this.routerPush({name: 'folders.store', params: {storeSlug: storeSlug}})
    },
    gotoWebshop(webshopSlug){
      this.routerPush({name: 'webshop.show', params: {webshopSlug: webshopSlug}, query: {tab: 'discount-codes'}})
    }
  }
}
</script>
