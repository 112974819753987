<template>
  <horizontal-promo-layout
    :promo="promo"
    :avatar="authorAvatar"
    :name="authorName"
    :profile-id="profileId"
    :profileType="profileType"
    :promoted-promo="promotedPromo"
    morphable_type="OnlinePromo"
    @like="makeHit"
    @favourite="makeFavourite"
  >
    <template #image>
      <div class="w-100 h-100" v-loading="imageIsLoading">
        <img
            class="lazy-load"
          v-lazy="imageUrl"
          :data-src="imageUrl"
          @click="showFullPromo"
          @load="imageIsLoading = false"
          alt=""
        >
      </div>
    </template>

    <template #description>
      <div class="d-flex flex-column justify-content-center h-100" v-visible="fireViewEvent">
        <div class="d-flex flex-column">
          <span class="title " v-html="promo.title" @click="showFullPromo"/>

          <div class="d-flex align-items-md-center align-items-start flex-md-row flex-column "  :class="[xsOnly? 'mb-1': 'mb-2']">
            <div class="price " v-if="promo.new_price || promo.discount">
              <template v-if="promo.new_price">
                <template v-if="promo.old_price">
                  <span class="price--old">€ {{ promo.old_price }}</span>
                  <span class="price--separator"> | </span>
                </template>
                <span class="price--new">€ {{ promo.new_price }}</span>
              </template>
              <span v-else-if="promo.discount">
                {{ promo.discount }} %
              </span>
            </div>
            <div class="d-flex justify-content-between align-items-center w-100 mt-1">
            <online-promo-webshop-label  :webshop="promo.webshop"/>
              <div v-if="xsOnly" class="expiration-date">
                <span v-if="promo.is_expired" class="expired-promo">
                  {{ $t('post.expired.expired') }}
                </span>
                <span v-else-if="promo.valid_to_formatted">
                  {{ $t('promo.expires_at', {date: promo.valid_to_formatted}) }}
                </span>
              </div>
            </div>
          </div>

          <div>
            <button class="more" @click="showFullPromo">{{ $t('post.meer_detail') }}</button>
          </div>
        </div>

        <button class="look-promo" @click="openInNewTab">
          {{ $t('post.go_webshop_btn') }}
        </button>
      </div>
    </template>
  </horizontal-promo-layout>
</template>

<script>
import {needAuth} from '@/mixins/auth/needAuth';
import {actions} from '@/mixins/post/actions';
import HorizontalPromoLayout from '@/components/feed/promo/desktop/HorizontalPromoLayout.vue';

export default {
  name: 'online-promo-horizontal-desktop',
  components: {HorizontalPromoLayout},
  mixins: [actions, needAuth],
  props: {
    promo: Object,
    isBangPromo: {
      type: Boolean,
      default() {
        return false
      }
    },
    vuexModule: {
      type: String,
      default: 'promo',
    },
    promotedPromo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imageLoadError: false,
      imageIsLoading: true,
    }
  },
  computed: {
    /**
     * @returns {any}
     */
    editor() {
      return this.promo.editor;
    },
    authorAvatar() {
      return !this.editor ? this.promo.webshop.image_url : this.editor.avatar_url
    },
    authorName() {
      return !this.editor ? this.promo.webshop.translation.title : this.editor.full_name
    },
    profileId() {
      return !this.editor ? this.promo.webshop.translation.route : String(this.editor.id)
    },
    profileType() {
      return !this.editor ? 'webshop' : 'user'
    },
    /**
     * Data for mixin
     * @returns {{morphable_type: string, entity: Object}}
     */
    entityData() {
      return {
        entity: this.promo,
        morphable_type: 'OnlinePromo'
      }
    },
    /**
     * Image url
     * @returns {*}
     */
    imageUrl() {
      return !this.imageLoadError ? this.promo.image_url : require('@/assets/images/promo.png')
    }
  },
  methods: {
    openInNewTab() {
      const eventDataToPush = {
        event: 'outgoing_click',
        event_type: 'click_online_promo',
        webshop_id: this.promo.webshop.id,
        webshop_title: this.promo.webshop.translation.title,
        online_promo_id: this.promo.id,
        online_promo_title: this.promo.title,
        clicked_link: this.promo.url,
        clicked_text: 'button',
      };
      this.processEventClick(eventDataToPush);
      window.open(this.promo.url, '_blank');
    },
    /**
     * Favourite
     */
    makeFavourite() {
      this.processFavourite()?.then(()=>{
        this.$emit('favorite');
      })
    },
    /**
     * Like
     */
    makeHit() {
      const isHit = this.promo.is_hit;
      const thisElem = this

      this.processHit()?.then(() => {
        thisElem.$emit('voted')
        if (!isHit) {
          this.processEventHit(this.prepareOnlinePromoEventData(this.promo));
        }
      })
    },
    showFullPromo() {
      this.$emit('opened')
      this.routerPush({name: 'online_promos.show', params: {id: String(this.promo.id)}});
    },
    fireViewEvent() {
      this.processEventView(this.prepareOnlinePromoEventData(this.promo));
    }
  }
}
</script>
