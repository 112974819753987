<template>
  <div>
    <comment
        v-for="entry in commentsData"
        :comment="entry"
        :key="entry.id"
        :show-reply="showReply"
        :guest-popup="guestPopup"
        @hit="$emit('hit', entry.id)">
    </comment>
  </div>
</template>

<script>
import Comment from '@/components/feed/comment/Comment';

export default {
  name: 'comments',
  emits: ['hit'],
  props: {
    commentsData: Array,
    showReply: {
      type: Boolean,
      default: true,
    },
    guestPopup: {
      type: Boolean,
      default: false
    },
  },
  components: {Comment}
}
</script>
