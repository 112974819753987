const getDefaultState = () => {
    return {
        promos: [],

    }
};

export default {
    namespaced: true,
    state: () => (getDefaultState()),

    getters: {
        PROMOTED_PROMO(state) {
            return state.promos;
        },
    },

    actions: {
        FETCH_PROMOTED_PROMO({state, commit}, params) {
             axios.get('/promoted-promo?', {params}).then((response) => {
                commit('UPDATE_PROMOTED_PROMO', response.data);

            }).catch((e) => {
                console.log(e);
            })
        },
        RESET_PROMOTED_PROMOS({commit}) {
            commit('SET_DEFAULT_DATA');
        },
    },

    mutations: {
        UPDATE_PROMOTED_PROMO(state, data) {
            return state.promos = data.data;
        },
        SET_DEFAULT_DATA(state) {
            const defaultState = getDefaultState();
            return state.promos = defaultState.promos;
        }
    }
}
