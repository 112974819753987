<template>
  <default-grid :show-right-container="false">
    <template v-slot:main-container>
      <div class="search-feed mb-5">
        <h1 class="search-header color-primary font-size-26 my-3" v-loading="promosLoading">
          <span v-if="promosLoading" v-t="'search.loading.label'"></span>
          <span v-text="headerText" v-else></span>
        </h1>

        <tabs :tabs="tabs" :default="'default'" @change="tabChange"></tabs>

        <template v-if="needToShowExpired">
          <h2 class="font-size-20" v-t="'search.header_valid_online_promos'" v-if="currentTab === 'online-promos'"></h2>
          <h2 class="font-size-20" v-t="'search.header_valid_folder_promos'" v-if="currentTab === 'folder-promos'"></h2>
        </template>

        <search-promos-list :items="PROMOS" :current-tab="currentTab"></search-promos-list>

        <infinite-loading @infinite="load" :key="currentTab" :distance="2000">
          <template #spinner>
            <div class="spinner-container" v-loading="true"></div>
          </template>
          <template #complete>
            <div class="color-primary font-size-18 my-3">
              <span v-t="'search.loading.no_results'" v-if="PROMOS_TOTAL < 1"></span>
            </div>
          </template>
          <template #error>
            <div></div>
          </template>
        </infinite-loading>

        <template v-if="needToShowExpired && PROMOS_IS_COMPLETE">
          <h2 class="font-size-20 text-header" v-t="'search.header_expired_promos'"></h2>
          <search-promos-list :items="EXPIRED_PROMOS" :current-tab="currentTab" vuex-module="promo-expired"></search-promos-list>

          <div class="color-primary font-size-18 my-3" v-if="EXPIRED_PROMOS_IS_COMPLETE">
            <span v-t="'search.loading.no_results'" v-if="EXPIRED_PROMOS_TOTAL < 1"></span>
          </div>
          <div class="w-100 centered mt-3" v-if="!EXPIRED_PROMOS_IS_COMPLETE && !EXPIRED_PROMOS_PROMISE">
            <el-button type="primary" class="hover:button-primary--dark"
                       :class="{'w-75':xsOnly,'w-50':!xsOnly}"
                       @click="loadExpiredPromos">
              <span v-t="'webshop.load_more'"></span>
            </el-button>
          </div>
        </template>
      </div>
    </template>
  </default-grid>
</template>

<script>
import DefaultGrid from '@/views/DefaultGrid';
import {mapActions, mapGetters} from 'vuex';
import Tabs from '@/components/feed/Tabs';
import InfiniteLoading from 'v3-infinite-loading';
import SearchPromosList from '@/components/feed/SearchPromosList';

export default {
  name: 'SearchView',
  components: {
    SearchPromosList,
    DefaultGrid,
    Tabs,
    InfiniteLoading
  },
  data() {
    return {
      currentTab: 'default',
      fetchOptions: {
        include_filters: ['types', 'morphable_types', 'categories', 'webshops', 'stores'],
        q: '',

        categories: [],
        webshops: [],
        stores: [],
        types: [],

        morphable_types: [],

        order: '',
        only_favourites: '',
        only_expired_promos: '',
        only_not_expired_promos: '',
      }
    }
  },
  computed: {
    ...mapGetters('promo', [
      'PROMOS',
      'PROMOS_PROMISE',
      'PROMOS_MORPHABLE_TYPES',
      'PROMOS_TOTAL',
      'PROMOS_IS_COMPLETE',
    ]),
    ...mapGetters('promo-expired', {
      'EXPIRED_PROMOS': 'PROMOS',
      'EXPIRED_PROMOS_IS_COMPLETE': 'PROMOS_IS_COMPLETE',
      'EXPIRED_PROMOS_PROMISE': 'PROMOS_PROMISE',
      'EXPIRED_PROMOS_TOTAL': 'PROMOS_TOTAL',
    }),
    ...mapGetters('filter', [
      'FILTERS_VALUES',
    ]),
    tabs() {
      const tabs = {};
      tabs['default'] = this.$t('search.tabs.default');
      tabs['folder-promos'] = this.$t('search.tabs.folder-promos');
      tabs['online-promos'] = this.$t('search.tabs.online-promos');
      tabs['favourites'] = this.$t('search.tabs.favourites');
      return tabs;
    },
    needToShowExpired() {
      return ['folder-promos', 'online-promos'].includes(this.currentTab);
    },
    headerText() {
      return this.$t('search.header', {query: this.currentQuery, total: this.PROMOS_TOTAL});
    },
    currentQuery() {
      return this.$route.query.q;
    },
    promosLoading(){
      return !!this.PROMOS_PROMISE;
    },
  },
  beforeMount() {
    this.resetPromos();
    this.RESET_FILTERS();
    this.SET_FILTER_OPENED({webshops_filter: true});
    this.SET_FILTER_OPENED({stores_filter: true});

    this.prepareFetchOptions();
    this.loadPromos();
  },
  beforeUnmount() {
    this.RESET_FILTERS();
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_ACTIVE',
      'SET_FILTER_INACTIVE',
      'RESET_FILTERS',
      'SET_FILTER_OPENED',
    ]),

    prepareFetchOptions() {
      this.SET_FILTER_ACTIVE('types_filter');
      this.SET_FILTER_ACTIVE('ch_filter');
      this.SET_FILTER_ACTIVE('webshops_filter');
      this.SET_FILTER_ACTIVE('stores_filter');

      this.fetchOptions.categories = this.FILTERS_VALUES.ch_filter;
      this.fetchOptions.webshops = this.FILTERS_VALUES.webshops_filter;
      this.fetchOptions.stores = this.FILTERS_VALUES.stores_filter;
      this.fetchOptions.types = this.FILTERS_VALUES.types_filter;

      this.fetchOptions.order = '';
      this.fetchOptions.only_favourites = '';
      this.fetchOptions.only_expired_promos = '';
      this.fetchOptions.only_not_expired_promos = '';
      this.fetchOptions.morphable_types = [];
      switch (this.currentTab) {
        case 'default':
          this.fetchOptions.morphable_types = ['Post'];
          break;
        case 'folder-promos':
          this.fetchOptions.only_not_expired_promos = 1;
          this.SET_FILTER_INACTIVE('types_filter');
          this.fetchOptions.morphable_types = ['FolderItem'];
          break;
        case 'online-promos':
          this.fetchOptions.only_not_expired_promos = 1;
          this.SET_FILTER_INACTIVE('types_filter');
          this.fetchOptions.morphable_types = ['OnlinePromo'];
          break;
        case 'favourites':
          this.fetchOptions.order = 'favourite_at';
          this.fetchOptions.only_favourites = 1;
          break;
      }
    },

    loadExpiredPromos() {
      this.fetchOptions.q = this.$route.query.q;

      const fetchOptions = _.clone(this.fetchOptions);
      fetchOptions.only_not_expired_promos = '';
      fetchOptions.only_expired_promos = 1;
      fetchOptions.include_filters = [];
      fetchOptions.per_page = 12;
      return this.$store.dispatch('promo-expired/FETCH_PROMOS', fetchOptions);
    },

    loadValidPromos() {
      this.fetchOptions.q = this.$route.query.q;
      return this.$store.dispatch('promo/FETCH_PROMOS', this.fetchOptions);
    },

    resetPromos() {
      this.$store.dispatch('promo/RESET_PROMOS');
      this.$store.dispatch('promo-expired/RESET_PROMOS');
    },

    loadPromos() {
      this.loadValidPromos();
    },

    load(state) {
      this.loadValidPromos().then(() => {
        if (this.PROMOS_IS_COMPLETE) {
          state.complete();
          if (this.needToShowExpired) {
            this.loadExpiredPromos();
          }
        } else {
          state.loaded();
        }
      });
    },
    tabChange(tabKey) {
      this.currentTab = tabKey;
      this.resetPromos();
      this.prepareFetchOptions();
      this.loadPromos();
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    currentQuery(to, from) {
      if (undefined === to) {
        return;
      }
      this.fetchOptions.q = to;
      this.prepareFetchOptions();
      this.resetPromos();
      this.loadPromos();
    },
    FILTERS_VALUES: {
      // the callback will be called immediately after the start of the observation
      deep: true,
      handler(newVal, oldVal) {
        this.prepareFetchOptions();
        this.resetPromos();
        this.loadPromos();
      }
    }
  }
}
</script>
