<template>
  <div
      class="horizontal-promo__footer"
      @click.self="commentsVisible = !commentsVisible"
  >
    <div
        class="author-info-horizontal"
        ref="container"
        @click.self="commentsVisible = !commentsVisible"
    >
      <a :href="profileLink" class="d-inline-flex align-items-center">

        <span class="el-avatar el-avatar--circle author-avatar">
          <img :src="authorAvatar" alt=""/>
        </span>

        <div class="d-flex flex-column">

          <p v-if="promo.quote" class="quote">{{ promo.quote }}</p>
          <div class="author-info">

            <div class="author-name">{{ authorName }}</div>
            <div class="dot-separator">&#183;</div>
            <span class="publication-time d-md-none">
             <img src="@/assets/images/date.svg" alt="Calendar Icon" loading="lazy">
              {{ promo.time_ago_short }}
            </span>
            <span class="publication-time d-none d-md-inline">
              {{ promo.time_ago }}
            </span>

          </div>

        </div>

      </a>

      <div class="comments" @click="commentsVisible = !commentsVisible">

        <span>{{ commentsLabel }}</span>
        <button class="comments__button">
          <i class="icon-comment-2"></i>
        </button>
        <button class="ps-0 comments__arrow-btn">
          <i class="icon-triangle-arrow"></i>
        </button>

      </div>
    </div>
  </div>

  <Transition>
    <div
        v-show="commentsVisible"
        class="horizontal-promo__comments mt-1"
    >
      <comment-form guest-popup/>
      <comments
          :comments-data="visibleCommentsData"
          guest-popup
          @hit="hitCommentProcess"
      />
      <show-more-comments
          :hidden-total="hiddenComments"
          :total="commentsCount"
          guest-popup
      />
    </div>
  </Transition>
</template>


<script>
import ShowMoreComments from '@/components/feed/comment/ShowMoreComments.vue';
import Comments from '@/components/feed/comment/Comments.vue';
import {actions} from '@/mixins/post/actions';
import {comments} from '@/mixins/comments';
import {needAuth} from '@/mixins/auth/needAuth';

export default {
  name: 'GuestComments',
  mixins: [actions, comments, needAuth],
  components: {Comments, ShowMoreComments},
  props: {
    morphable_type: {
      type: String,
      default: ''
    },
    promo: {
      type: Object,
      default: () => ({})
    },
  },

  data() {
    return {
      commentsVisible: false,
    }
  },

  computed: {
    /**
     * number of hidden comments
     * @returns {number}
     */
    hiddenComments() {
      return this.commentsCount - this.visibleComments;
    },

    /**
     * comments that are visible
     * @returns {Array}
     */
    visibleCommentsData() {
      return _.takeRight(this.comments, this.visibleComments);
    },

    /**
     * label for comments
     * @returns {string}
     */
    commentsLabel() {
      if (this.xsOnly) {
        return this.commentsCount;
      }
      return this.commentsCount === 1 ? `${this.commentsCount} reactie` : `${this.commentsCount} reacties`;
    },

    /**
     * avatar URL of the comment author
     * @returns {string}
     */
    authorAvatar() {
      if (this.morphable_type === 'FolderItem') {
        return !this.promo.editor ? this.promo.folderPage.folder.store.image_url : this.promo.editor.avatar_url;
      }
      if (this.morphable_type === 'OnlinePromo') {
        return !this.promo.editor ? this.promo.webshop.image : this.promo.editor.avatar_url;
      }
      return this.promo.user.avatar_url;
    },

    /**
     * store title
     * @returns {string}
     */
    storeTitle() {
      const folderPage = this.promo.folderPage;
      return folderPage ? folderPage.folder.store.translation.title : '';
    },

    /**
     * author's name,
     * @returns {string}
     */
    authorName() {
      if (this.morphable_type === 'FolderItem') {
        return !this.promo.editor ? this.storeTitle : this.promo.editor.full_name;
      }
      if (this.morphable_type === 'OnlinePromo') {
        return !this.promo.editor ? this.promo.webshop.translation.title : this.promo.editor.full_name;
      }
      return this.promo.user.full_name;
    },

    /**
     * profile ID
     * @returns {string|number}
     */
    profileId() {
      if (this.morphable_type === 'FolderItem') {
        return !this.promo.editor ? this.promo.folderPage.folder.store.translation.route : String(this.promo.editor.id);
      }
      if (this.morphable_type === 'OnlinePromo') {
        return !this.promo.editor ? this.promo.webshop.translation.route : String(this.promo.editor.id);
      }
      return this.promo.user?.id;
    },

    /**
     * profile type
     * @returns {string}
     */
    profileType() {
      if (this.morphable_type === 'FolderItem') return !this.promo.editor ? 'store' : 'user';
      if (this.morphable_type === 'OnlinePromo') return !this.promo.editor ? 'webshop' : 'user';
      return 'user';
    },

    /**
     * profile link .
     * @returns {string}
     */
    profileLink() {
      let routeBody = {name: 'home'};
      if (this.profileType === 'user') {
        routeBody = {name: 'profile', params: {userId: String(this.profileId)}};
      } else if (this.profileType === 'webshop') {
        routeBody = {name: 'webshop.show', params: {webshopSlug: this.profileId}};
      } else if (this.profileType === 'store') {
        routeBody = {name: 'folders.store', params: {storeSlug: this.profileId}};
      }
      return this.$router.resolve(routeBody).href;
    },
  },

  mounted() {
    this.commentsCount = this.promo.comments_count;
    this.comments = this.promo.comments;
    this.visibleComments = _.min([3, this.promo.comments.length]);
  },

}
</script>


<style scoped>

</style>
