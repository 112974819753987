<template>
  <footer class="position-absolute start-0 end-0">
    <div class="categories-section">
      <div class="categories-section__header">
        <default-space>
          <h3>{{ $t('footer.title') }}</h3>
        </default-space>
      </div>
      <div class="categories-section__categories">
        <default-space container-class="grid-container">
          <div v-for="(category, i) in categories" :key="i">
            <router-localized-link
                :to="{name : 'promos', params: category.id ? {categorySlug: category.translation.route} : {}}"
            >
              {{ category.translation.simplified_title }}
            </router-localized-link>
          </div>
        </default-space>
      </div>
    </div>
    <div class="pages-section">
      <default-space container-class="d-flex">
        <div class="pages-section__container">
          <div>
            <router-localized-link :to="{name: 'promos'}" v-t="'menu.main.promos'"/>
          </div>
          <div>
            <router-localized-link :to="{name: 'folders'}" v-t="'menu.main.folders'"/>
          </div>
          <div v-if="ARTICLES.length">
            <router-localized-link :to="{name: 'news'}" v-t="'header.menu.news'"/>
          </div>

          <div>
            <router-localized-link :to="{name: 'stores'}" v-t="'menu.main.stores'"/>
          </div>

          <div>
            <router-localized-link :to="{name: 'webshops'}" v-t="'menu.main.webshops'"/>
          </div>

          <div>
            <router-localized-link :to="{name: 'brands'}" v-t="'menu.main.brands'"/>
          </div>

          <div>
            <router-localized-link :to="{name: 'products'}" v-t="'menu.main.products'"/>
          </div>

          <template v-for="(page, i) in pagesInFooter" :key="i">
            <div>
              <router-localized-link :to="{path: page.route}">{{ page.title }}</router-localized-link>
            </div>

          </template>
        </div>
      </default-space>
    </div>
    <div class="promojager-section">
      <div class="container">
        <el-row>
          <el-col :xs="24" :md="10" class="promojager-section__intro">
            <div class="intro-title">
              {{$t('footer.intro.title', {members: USERS_COUNT})}}
            </div>
            <div class="intro-text"
                 v-html="$t('footer.intro.text', {kortingcodeHref: getRouteHref({name: 'promos', query: {'promos_filter[]': ['discount_code']}}), folderHref: getRouteHref({name: 'folders'}), productHref: getRouteHref({name: 'products'}), brandHref: getRouteHref({name: 'brands'}), webshopHref: getRouteHref({name: 'webshops'}), storeHref: getRouteHref({name: 'stores'}), registerHref: getRouteHref({name: 'register'}), promosHref: getRouteHref({name: 'promos'})})">
            </div>

          </el-col>
          <el-col :xs="24" :md="6" class="promojager-section__pages">
            <div class="pages-links">
              <template v-for="(page, i) in pagesInFooter" :key="i">
                <div>
                  <router-localized-link :to="{path: `/${page.route}`}">{{ page.title }}</router-localized-link>
                </div>
              </template>
              <div>
                <a href="https://pricebuddy.be/" target="_blank">PriceBuddy</a>
              </div>
              <div>
                <a href="https://newsletter.promojagers.be/ontvangen" target="_blank">Onze nieuwsbrief</a>
              </div>

            </div>

          </el-col>
          <el-col :xs="24" :md="8" class="promojager-section__social">
            <div class="mobile-apps">
              <div class="mobile-apps__title" v-t="'footer.download_app'"/>
              <a href="https://bit.ly/promojagers-ios">
                <img v-if="LOCALE==='nl'" class="app-store" src="@/assets/images/app/app-store-nl.svg" alt="" loading="lazy">
                <img v-else class="app-store" src="@/assets/images/app/app-store-fr.svg" alt="" loading="lazy">
              </a>
              <div class="rating">
                <i class="icon-starpopular "></i>
                4.5 - {{ $t('footer.rating') }}
              </div>
              <a href="https://bit.ly/promojagers-android">
                <img v-if="LOCALE==='nl'" class="google-play" src="@/assets/images/app/google-play-nl.png" alt="" loading="lazy">
                <img v-else class="google-play" src="@/assets/images/app/google-play-fr.png" alt="" loading="lazy">
              </a>
              <div class="rating">
                <i class="icon-starpopular"></i>
                4.5 - {{ $t('footer.rating') }}
              </div>
            </div>
            <div class="social-media">
              <div class="social-media__title" v-t="'footer.follow_social_media'"/>
              <div class="contacts d-inline-flex align-items-center ">
                <a href="https://www.facebook.com/groups/promojagersbelgie/" class="me-2" target="_blank"><i
                    class="icon-Facebook-2"/></a>
                <a href="https://www.instagram.com/promojagersbelgie_official" class="me-2" target="_blank">
                  <i class="icon-Instagram-2"/>
                </a>
                <a href="https://www.linkedin.com/company/promojagers/" class="me-2" target="_blank"><i
                    class="icon-Linkedin"/></a>
                <a href="https://www.tiktok.com/@promojagers_belgie" class="me-2" target="_blank"><i
                    class="icon-TikTok"/></a>
                <a href="https://www.youtube.com/@promojagersbelgie" target="_blank"><i class="icon-YouTube"/></a>
              </div>
              <div class="trustpilot">
                <img class="w-100" src="@/assets/images/trustpilot.svg" alt="Trust Pilot" loading="lazy">
              </div>
            </div>

          </el-col>
        </el-row>
      </div>
    </div>

    <div class="policy-section">
      <default-space container-class="d-flex justify-content-between">
        <div class="py-1 d-md-inline-flex">
          <div class="policy-section__promojagers">
            © Promojagers {{ currentYear }}
          </div>

          <div class="d-md-inline-flex d-none">

            <span class="mx-1">|</span>
            <router-localized-link class="hover:text-underline" :to="{path: '/terms'}"
                                   v-t="'footer.terms_and_conditions'">
            </router-localized-link>
            <span class="mx-1">|</span>
            <router-localized-link class="hover:text-underline" :to="{path: '/privacy-policy'}">Privacy policy
            </router-localized-link>
          </div>
        </div>
        <div class="contacts d-md-none d-inline-flex align-items-center px-2">
          <a href="https://www.facebook.com/promojagersbelgie" class="me-2" target="_blank"><i class="icon-Facebook-2"/></a>
          <a href="https://www.instagram.com/promojagersbelgie_official" class="me-2" target="_blank">
            <i class="icon-Instagram-2"/>
          </a>
          <a href="https://www.linkedin.com/company/promojagers/" class="me-2" target="_blank"><i
              class="icon-Linkedin"/></a>
          <a href="https://www.tiktok.com/@promojagers_belgie" class="me-2" target="_blank"><i class="icon-TikTok"/></a>
          <a href="https://www.youtube.com/@promojagersbelgie" target="_blank"><i class="icon-YouTube"/></a>
        </div>
      </default-space>
      <default-space class="d-md-none">
        <div class="d-md-inline-flex">
          <a class="hover:text-underline" href="https://pricebuddy.be/" target="_blank">PriceBuddy</a>
          <span class="mx-1">|</span>
          <router-localized-link class="hover:text-underline" :to="{path: '/terms'}"
                                 v-t="'footer.terms_and_conditions'">
          </router-localized-link>
          <span class="mx-1">|</span>
          <router-localized-link class="hover:text-underline" :to="{path: '/privacy-policy'}">Privacy policy
          </router-localized-link>
        </div>
      </default-space>
    </div>
  </footer>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink';
import DefaultSpace from '@/views/DefaultSpace.vue';

export default {
  name: 'main-footer',
  components: {DefaultSpace, RouterLocalizedLink},
  computed: {
    ...mapGetters('page', ['PAGES', 'PAGES_LOADED']),
    ...mapGetters('auth', ['LOCALE']),
    ...mapGetters('category', ['CATEGORIES']),
    ...mapGetters('article', [
      'ARTICLES'
    ]),
    ...mapGetters('users', [
      'USERS_COUNT',
    ]),

    currentYear() {
      return new Date().getFullYear()
    },
    pagesInFooter() {
      const thisElem = this
      const excludedPages = ['privacy-policy', 'mobile-app', 'terms', 'folders']

      return _.filter(this.PAGES, function (page) {
        return page.in_footer && thisElem.$i18n.locale === page.locale.slug && !excludedPages.includes(page.slug);
      })
    },
    mobileApp() {
      return _.find(this.PAGES, {slug: 'mobile-app'})
    },
    /**
     * Filtered Categories
     * @returns {*}
     */
    categories() {
      return this.CATEGORIES.filter((c) => c.translation?.simplified_title)
    },
  },
  beforeMount() {
    if (!this.CATEGORIES.length) {
      this.FETCH_CATEGORIES()
    }

    if (!this.PAGES_LOADED) {
      this.FETCH_PAGES()
    }
    // if (!this.USERS_COUNT){
      this.FETCH_USERS_COUNT();
    // }

  },
  methods: {
    ...mapActions('page', ['FETCH_PAGES']),
    ...mapActions('category', ['FETCH_CATEGORIES']),
    ...mapActions('users', [
      'FETCH_USERS_COUNT',
    ]),
    getRouteHref(route) {
      return this.$localizePath(this.$router.resolve(route).href, this.LOCALE);

    }
  },
}
</script>
