import store from '@/store';

export const discountCodes = {
    mounted() {
        this.emitter.on('copy-discount-code', (parameters) => {
            if (this.id===parameters.data.id){
                this.copyDiscountCode(parameters.data, parameters.eventOutgoingClickMeta);
            }
        });
    },
    methods: {
        copyDiscountCode(post, eventOutgoingClickMeta) {
            if (post.discount_url && _.has(eventOutgoingClickMeta, 'event')) {
                const eventDataToPush = eventOutgoingClickMeta;
                eventDataToPush.user_id = _.get(store.getters['auth/USER'], 'id', 0)
                eventDataToPush.clicked_text = 'discount-code-button';
                eventDataToPush.clicked_link = post.discount_url;
                this.processEventClick(eventDataToPush);
            }

            this.$copyText(post.discount_code, undefined, (error, event) => {
                setTimeout(() => {
                    if (post.discount_url) {
                        if (!this.webview) {
                            localStorage.setItem('CopiedDiscountCodeId', post.id);
                            window.open(window.location.href, '_blank');
                            window.open(post.discount_url, '_self');
                            return;
                        }

                        window.open(post.discount_url, '_blank');
                        this.notifyDiscountCodeCopied(post.discount_code);
                        return;
                    }

                    this.notifyDiscountCodeCopied(post.discount_code);
                }, 1500)
            });
        },
        notifyDiscountCodeCopied(discountCode) {
            this.$notify.success({
                title: this.$t('post.notifications.copied.title'),
                message: this.$t('post.notifications.copied.content', {text: discountCode}),
                position: this.notificationPosition,
                duration: this.notificationDuration,
            });
        }
    },
    watch: {
        '$route.path'() {
            this.$notify?.closeAll()
        }
    },
};
