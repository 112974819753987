<template>
  <div class="popular-card">
    <div class="popular-card__title">{{ title }}</div>
    <div class="popular-card__body">
      <div class="popular-item cursor-pointer" v-for="item in items" :key="item.id" @click="$emit('view-single', item.translation.route)">
        <i v-if="item.icon" :class="`icon-${item.icon} `"/>
        <img v-else :src="item.image_url" alt="">
        <div class="popular-item__content">
          {{ get(item, titleKey) }}
        </div>
      </div>
    </div>
    <div class="popular-card__footer" v-if="showViewAll">
      <router-localized-link
          :to="viewAllRoute"
          class="view-all-link "
      >
        <span v-t="viewAllText"/>
        <i class="icon-triangle-arrow ms-2"/>
      </router-localized-link>
    </div>
  </div>
</template>
<script>
import {get} from 'lodash'
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink.vue';

export default {
  name: 'PopularCard',
  components: {RouterLocalizedLink},
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
    titleKey: {
      type: String,
      required: true,
    },
    viewAllText: {
      type: String,
      default: '',
    },
    viewAllRoute: {
      type: String,
      default: '',
    },
    showViewAll: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    get
  },
}
</script>

<style scoped>

</style>
