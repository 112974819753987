<template>
  <default-grid :show-right-container="false">
    <template v-slot:left-container>
      <left-bar show-webshop-logo  show-store-mailer type="WEBSHOP" :show-filters-list="false"></left-bar>
    </template>
    <template v-slot:main-container>
      <div class="promos-feed">
        <div class="d-flex align-items-start gap-2 header-container">
          <h1 class="promos-feed-title" v-html="h1Text"/>
          <button  class="look-subscription d-md-none" @click="subscriptionVisible =! subscriptionVisible"  >
            <i v-if="CURRENT_WEBSHOP.subscription" class="icon-bell-3"/>
            <i v-else class="icon-bell"/>
          </button>
        </div>

        <tabs
            ref="tabs_component"
            :tabs="tabs"
            :default="currentTab"
            @change="tabChange"
            change-query
        />
        <div>
          <div class="mt-3 mt-md-0">
            <Transition>
              <div v-show="subscriptionVisible" class="store-mailer-mobile__wrapper">
                <subscription  type="WEBSHOP" :dividers="false"></subscription>
              </div>
            </Transition>
            <search-promos-list
                :items="PROMOS"
                :current-tab="currentTab"
                :vuex-modules="{'post': 'promo', 'online_promo': 'promo'}"
            />

            <div v-if="!PROMOS_PROMISE && !PROMOS_IS_COMPLETE" class="w-100 centered my-5 pb-5">
              <el-button type="primary"
                         :class="{'w-75':xsOnly,'w-50':!xsOnly}"
                         @click="loadResults">
                <span v-t="'webshop.load_more'"></span>
              </el-button>
            </div>
          </div>

          <!--        <extension-preview></extension-preview>-->

          <h2 class="color-primary my-3" :class="{'font-size-18':xsOnly,'font-size-20':!xsOnly}" v-text="h2Text"></h2>

          <div class="discount-code-google-chrome">
            <extension-preview :title="CURRENT_WEBSHOP.translation.title"
                               bg-class="bg-neutral"
                               btn-type="warning"
                               btn-text-class="text-dark"/>
          </div>

          <h2 class="color-primary my-3" :class="{'font-size-18':xsOnly,'font-size-20':!xsOnly}" v-t="'webshop.seo.top_questions'"></h2>

          <div v-for="(faqItem, key) in faqData" :key="key">
            <el-divider class="my-3"/>

            <div class="color-text-primary font-size-20">
              <i class="icon-minus"></i>&nbsp; <span v-text="faqItem.question"></span>
            </div>

            <div class="color-text-primary font-size-14 m-3" v-html="faqItem.answer"></div>
          </div>

          <div class="color-text-primary my-5 seo-content" v-html="CURRENT_WEBSHOP.translation.seo_content"></div>

          <div class="color-text-primary" v-if="seoTableData.length">
            <h3 class="h4" v-html="$t('webshop.seo.table_header',{webshop: CURRENT_WEBSHOP.translation.title})"></h3>

            <div class="mb-5">
              <el-table :data="seoTableData" border>
                <el-table-column prop="published_at" :label="$t('webshop.seo.table.row_1')" :min-width="25"/>
                <el-table-column prop="short_content" :label="$t('webshop.seo.table.row_2')" :min-width="50"/>

                <el-table-column prop="discount_code" :label="$t('webshop.seo.table.row_3')" :min-width="25">
                  <template #default="scope">
                    <span :class="scope.row.code_class">
                      {{ scope.row.discount_code }}
                    </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </template>
  </default-grid>
</template>

<script>
import {useHead} from '@vueuse/head';
import DefaultGrid from '@/views/DefaultGrid';
import { mapGetters} from 'vuex';
import LeftBar from '@/components/left_bar/LeftBar';
import Tabs from '@/components/feed/Tabs';
import ExtensionPreview from '@/components/footer/ExtensionPreview';
import {dates} from '@/mixins/dates';
import {breadcrumbs} from '@/mixins/seo/breadcrumbs';
import WebshopRoute from '@/router/guards/WebshopRoute';
import SearchPromosList from '@/components/feed/SearchPromosList';
import {store_webshop_brand_product} from '@/mixins/group/store_webshop_brand_product';
import Subscription from '@/components/left_bar/store/Subscription.vue';

export default {
  name: 'WebshopView',
  components: {
    SearchPromosList,
    ExtensionPreview,
    Tabs,
    LeftBar,
    DefaultGrid,
    Subscription
  },
  mixins: [dates, breadcrumbs, store_webshop_brand_product],
  data() {
    return {
      subscriptionVisible: false,
      currentTab: _.get(this.$route.query, 'tab', 'default'),
      fetchOptions: {
        type_kind_not: this.$consts.KIND.QUESTION,
        include_filters: ['types'],
        types: [],

        q: '',
        per_page: 15,
      },
      faqData: []
    }
  },
  computed: {
    ...mapGetters('webshop', [
      'CURRENT_WEBSHOP',
    ]),
    /**
     * Tabs
     * @returns {{}}
     */
    tabs() {
      const tabs = {};
      tabs['default'] = this.$t('webshop.tabs.default');
      tabs['discount-codes'] = this.$t('product.tabs.discount-codes');
      tabs['online-promos'] = this.$t('webshop.tabs.online-promos');
      tabs['posts'] = this.$t('webshop.tabs.posts');
      return tabs;
    },
    /**
     * Title text
     * @returns {*}
     */
    h1Text() {
      const date = new Date();
      const m = date.toLocaleString(this.$i18n.locale, {month: 'long'});
      const y = date.getFullYear();
      return this.$t('webshop.webshop_page_h1', {webshop: this.CURRENT_WEBSHOP.translation.title, month: m, year: y});
    },

    h2Text() {
      return this.$t('webshop.webshop_page_h2', {webshop: this.CURRENT_WEBSHOP.translation.title});
    },

    seoTableData() {
      const thisElem = this;
      return _.map(this.CURRENT_WEBSHOP.live_discounts, function (post) {
        let codeClass = 'text-dark';
        if (post.expired_at) {
          const date = new Date(post.expired_at.timestamp * 1000);
          codeClass = (date > new Date()) ? 'text-success' : 'color-primary';
        }

        return {
          published_at: thisElem.dateStringFromTimestamp(_.get(post, 'published_at.timestamp', null)),
          short_content: post.short_content,
          discount_code: post.discount_code,
          code_class: codeClass,
        }
      });
    },
  },
  // need this because Guards doesnt work when the same route params changing
  async beforeRouteUpdate(to, from) {
    if (from.params.webshopSlug !== to.params.webshopSlug) {
      await WebshopRoute(to);
    }
  },
  created() {
    useHead({
      // Can be static or computed
      script: [
        {
          type: 'application/ld+json',
          children: JSON.stringify(this.getFaqStructure())
        },
        {
          type: 'application/ld+json',
          children: JSON.stringify(this.getBreadcrumbStructure())
        },
      ],
    })
  },
  methods: {
    prepareFetchOptions() {
      this.SET_FILTER_ACTIVE('types_filter');

      this.fetchOptions.webshop = this.CURRENT_WEBSHOP.id;
      this.fetchOptions.types = this.FILTERS_VALUES.types_filter;
      this.fetchOptions.type_kind = '';
      this.fetchOptions.order = '';

      switch (this.currentTab) {
        case 'default':
          this.fetchOptions.morphable_types = [];
          this.fetchOptions.order = 'codes_first';
          break;
        case 'discount-codes':
          this.SET_FILTER_INACTIVE('types_filter');
          this.fetchOptions.morphable_types = ['Post'];
          this.fetchOptions.type_kind = this.$consts.KIND.DISCOUNT;
          this.fetchOptions.order = 'codes_first';
          break;
        case 'online-promos':
          this.SET_FILTER_INACTIVE('types_filter');
          this.fetchOptions.types = [];
          this.fetchOptions.morphable_types = ['OnlinePromo'];
          this.fetchOptions.order = 'published_at';
          break;
        case 'posts':
          this.fetchOptions.morphable_types = ['Post'];
          this.fetchOptions.order = 'published_at';
          break;
        default:
          break;
      }
    },
    getFaqStructure() {
      const params = {
        'webshop': this.CURRENT_WEBSHOP.translation.title,
        'total_posts': this.CURRENT_WEBSHOP.total_posts,
        'total_codes': this.CURRENT_WEBSHOP.total_valid_codes,
        'last_post_content': this.CURRENT_WEBSHOP.last_post_content,
      };
      const mainEntity = [];
      this.faqData = [];
      for (let i = 1; i <= 4; i++) {
        mainEntity.push({
          '@type': 'Question',
          'name': this.$t('webshop.seo.question_' + i, params),
          'acceptedAnswer': {
            '@type': 'Answer',
            'text': this.$t('webshop.seo.answer_' + i, params)
          }
        });
        this.faqData.push({
          question: this.$t('webshop.seo.question_' + i, params),
          answer: this.$t('webshop.seo.answer_' + i, params)
        });
      }
      return {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        'mainEntity': mainEntity
      };
    }
  }
}
</script>
