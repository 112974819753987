<template>
  <div ref="carousel" class="folders-carousel-container w-100 position-relative"
       :class="{[childClass]: listType === 'carousel'}">
    <!--
      Scroll header
    -->
    <div :class="{[headerClass]: true, [childClass]: listType !== 'carousel', 'flex-column align-items-start':xsOnly}" ref="top_folders_header">
      <h3 class="header-label my-3" v-html="title"/>

      <div v-if="showListTypeSwitcher" class="d-flex align-items-center" :class="{'justify-content-around': !xsOnly, 'w-100': xsOnly, 'justify-content-between': xsOnly}">
        <div class="list-type-switcher color-primary">
          <i class="icon-list-vector" :class="{'active': listType === 'list'}" @click="changeListType('list')"/>
          <i class="icon-carousel-vector" :class="{'active': listType === 'carousel'}" @click="changeListType('carousel')"/>
        </div>
        <el-select v-model="form.selectFilter" class="folders-rounded-select" @change="filteredFolders">
          <el-option
              :key="0"
              :label="$t('news.recommended')"
              :value="0"
          />
          <el-option
              :key="1"
              :label="$t('folder.pager.next_week')"
              :value="1"
          />
        </el-select>
      </div>
    </div>

    <!--
      Navigation + Scrollbar
    -->
    <div v-if="listType === 'carousel'" class="navigation-line" ref="top_folders_header">
      <div class="nav-buttons">
        <button
          v-if="isLeftArrowActive"
          class="float-start"
          ref="leftArrow"
          @click="scrollPrev"
          @keyup.left="scrollPrev"
          @keyup.right="focusRightArrow"
        >
          <i class="icon-triangle-arrow icon-flipped"/>
        </button>

        <el-scrollbar
          class="w-100"
          :native="false"
          ref="scrollbar_ref"
          :min-size="40"
          v-loading="isLoading"
          @touchstart="handleTouchStart"
          @touchmove="handleTouchMove"
          @touchend="handleTouchEnd"
          @scroll="scrolling"
        >
          <div class="scrollbar-flex-content">
            <div v-for="(folder, i) in filteredFolders" :key="i" class="scrollbar-item">
              <div class="scrollbar-item-style" :style="previewItemContainerStyle">
                <folder-preview :folder="folder" :vuex-module="vuexModule"
                                :is-linked-to-store-page="isLinkedToStorePage"/>
              </div>
            </div>
          </div>
        </el-scrollbar>

        <button
          v-if="isRightArrowActive"
          class="float-end"
          ref="rightArrow"
          @click="scrollNext"
          @keyup.right="scrollNext"
          @keyup.left="focusLeftArrow"
        >
          <i class="icon-triangle-arrow"/>
        </button>
      </div>
    </div>

    <!--
     Scrollbar
   -->
    <template v-if="listType === 'list'">
      <div class="d-flex justify-content-center">
        <div class="folders-list-container">
          <template v-for="(folder, i) in filteredFolders" :key="i">
            <div class="folder-list-item">
              <folder-preview :folder="folder" :vuex-module="vuexModule" :is-linked-to-store-page="isLinkedToStorePage"/>
            </div>
          </template>
          <div class="folder-list-item" v-if="showLoadMoreBlock && filteredFolders.length">
            <div
              class="load-more-block"
              @click="$emit('load')"
            >
             <span class="d-md-none">
                {{$t('folder.load_more_folders_mobile', {value: pendingFolders})}}
             </span>
             <span class="d-none d-md-inline">
                {{$t('folder.load_more_folders', {value: pendingFolders})}}
             </span>
            </div>
          </div>
        </div>
      </div>
    </template>
</div>
</template>

<script>
import FolderPreview from '@/components/feed/folder/FolderPreview.vue';
import {dates} from '@/mixins/dates';

export default {
  name: 'folders-carousel',
  emits: ['load'],
  components: {FolderPreview},
  props: {
    headerClass: {
      type: String,
      default: 'folders-carousel-header',
    },
    title: String,
    folders: Array,
    childClass: String,
    foldersTotal: {
      type: Number,
      default: 0,
    },
    vuexModule: {
      type: String,
      default: 'folder',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    customWidth: {
      type: String,
      default: '275px'
    },
    isLinkedToStorePage: {
      type: Boolean,
      default: false,
    },
    defaultListType: {
      type: String,
      default: 'carousel',
    },
    showListTypeSwitcher: {
      type: Boolean,
      default: false,
    },
    hideArrowsIfEnded: {
      type: Boolean,
      default: false,
    },
    showLoadMoreBlock: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [dates],
  data() {
    return {
      listType: this.defaultListType,
      containerWidth: 0,
      currentScroll: 0,
      currentScrollPx: 0,
      loadingEmitted: false,
      form: {
        selectFilter: 0,
      },
    }
  },
  computed: {
    /**
     * @returns {boolean}
     */
    isLeftArrowActive() {
      return this.currentScrollPx > 0
    },
    /**
     * @returns {boolean}
     */
    isRightArrowActive() {
      return this.currentScrollPx + 5 < this.maxScrollPx
    },
    /**
     * Pixels after visible zone
     * @returns {number}
     */
    maxScrollPx() {
      return this.folders.length * this.widthInPx - this.containerWidth;
    },
    /**
     * Style for folder container
     * @returns {{width: string}}
     */
    previewItemContainerStyle() {
      return {
        'width': this.widthInPx + 'px'
      }
    },
    /**
     * Width of one folder in px
     * @returns {number}
     */
    widthInPx() {
      let width = this.customWidth

      if (!this.containerWidth) {
        return;
      }

      if (width.indexOf('%') !== -1) {
        width = width.replace(/%/g, '')

        return this.containerWidth * Number(width) / 100
      }

      width = width.replace(/[a-zA-Z]/g, '')

      return Number(width)
    },
    /**
     * Clipping of folders ending this week
     * @returns {Object}
     */
    filteredFolders() {
      let filterFolders = this.folders;
      let dateSunday = new Date();
      dateSunday.setDate(dateSunday.getDate() + (1 + 6 - dateSunday.getDay()) % 6);
      dateSunday = this.dateStringFromISO(dateSunday, 'Y-m-d');

      if (this.form.selectFilter === 1) {
        filterFolders = _.filter(this.folders, function(o) { return o.valid_to > dateSunday; });
      }

      return filterFolders;
    },
    /**
     * number of folders pending
     * @returns {Object}
     */
    pendingFolders(){
      return  this.foldersTotal - this.folders.length
    }
  },
  mounted() {
    new ResizeObserver(() => this.calculateContainerWidth()).observe(this.$refs.top_folders_header);
    document.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleTouchStart(event) {
      this.startX = event.touches[0].clientX;
    },
    handleTouchMove(event) {
      this.currentX = event.touches[0].clientX;
    },
    handleTouchEnd() {
      if (this.startX - this.currentX > 20) {
        this.scrollNext()
      } else if (this.currentX - this.startX > 20) {
        this.scrollPrev()
      }
    },
    handleScroll() {
      const carousel = this.$refs.carousel;
      if (!carousel) {
        return
      }
      const rect = carousel.getBoundingClientRect();

      if ((rect.top <= window.innerHeight / 2) && (rect.bottom >= window.innerHeight / 2)) {
        this.focusRightArrow(false)
      }
    },
    scrolling(value) {
      this.currentScrollPx = value.scrollLeft;
    },
    calculateContainerWidth() {
      if (this.$refs.top_folders_header) {
        this.containerWidth = this.$refs.top_folders_header.clientWidth;
      }
    },
    scrollNext() {
      this.currentScroll++;
    },
    scrollPrev() {
      if (this.currentScroll > 0) {
        this.currentScroll--;
      }
    },
    focusRightArrow(scroll = true) {
      if (this.$refs.rightArrow) {
        this.$refs.rightArrow.focus()
      }
      if (scroll) {
        this.scrollNext()
      }
    },
    focusLeftArrow(scroll = true) {
      if (this.$refs.leftArrow) {
        this.$refs.leftArrow.focus()
      }
      if (scroll) {
        this.scrollPrev()
      }
    },
    changeListType(listType) {
      this.listType = listType

      if (listType === 'carousel') {
        this.$nextTick(() => this.focusRightArrow(false))
      }
    }
  },
  watch: {
    maxScrollPx(value) {
      if (value) {
        this.$emit('rendered', {name: 'folders', maxScrollPx: value})
      }
    },
    currentScroll(value) {
      this.currentScrollPx = this.widthInPx * value;
      this.$refs.scrollbar_ref.setScrollLeft(this.widthInPx * value);
    },
    currentScrollPx(value) {
      if (value > this.maxScrollPx && !this.loadingEmitted) {
        this.$emit('load');
        this.loadingEmitted = true;
        setTimeout(() => {
          this.loadingEmitted = false;
        }, 3000);
      }
    }
  }
}
</script>
