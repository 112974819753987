<template>
  <div class="horizontal-promo" :class="{'opened-comments': commentsVisible, }">
    <div class="horizontal-promo__main">
      <!--   Image   -->
      <div class="image-section">
        <slot name="image">
        </slot>
      </div>

      <!--   Content   -->
      <div class="content-section">
        <div class="content-section__header">
          <div class="reclame me-2" v-if="promotedPromo">
            {{$t('landing.promos.reclame')}}
          </div>
          <div class="voting me-2" v-else>
            <span class="voting__value" @click="xsOnly? $emit('like'):null">{{ promo.rating_calculated }}</span>
            <div
                class="voting__block  me-2"
                :class="{'active': promo.is_hit}"
                @click="$emit('like')"
            >
              {{$t('post.vote')}}
            </div>
          </div>

          <div class="d-flex align-items-center">
            <div v-if="!xsOnly" class="expiration-date me-2">
              <span v-if="promo.is_expired" class="expired-promo">
                {{ $t('post.expired.expired') }}
              </span>
              <span v-else>
                {{
                  promo.valid_to_formatted ? $t('promo.expires_at', {date: promo.valid_to_formatted}) : ''
                }}
              </span>
            </div>
            <share v-if="!xsOnly" class="me-2" @share="processShare"></share>
            <div class="favorite-wrapper">
              <i
                :class="{'active icon-heart-2': promo.is_favourite, 'icon-heart': !promo.is_favourite}"
                @click="$emit('favourite')"
              />
            </div>
          </div>
        </div>
        <div class="content-section__description">
          <slot name="description"/>

        </div>
      </div>
    </div>

    <!--
      Footer
    -->
    <div class="horizontal-promo__footer" @click.self="commentsVisible = !commentsVisible">
      <div class="author-info-horizontal" ref="container" @click.self="commentsVisible = !commentsVisible">
        <router-localized-link :to="routeBody" class="d-inline-flex align-items-center">
          <el-avatar shape="circle" class="author-avatar">
            <img :src="avatar" :alt="name" loading="lazy"/>
          </el-avatar>



          <div class="d-flex flex-column">
            <p v-if="promo.quote" class="quote">
              {{ promo.quote }}
            </p>
            <div class="author-info">
              <div class="author-name">{{ name }} </div>
              <div class="dot-separator">&#183;</div>
              <span class="publication-time">
                <img src="@/assets/images/date.svg" alt="Calendar Icon" loading="lazy" class="d-md-none">
                {{ xsOnly?  promo.time_ago_short : promo.time_ago }}</span>
            </div>
          </div>
        </router-localized-link>

        <div class="comments" @click="commentsVisible = !commentsVisible">
          <span >{{ commentsLabel }}</span>
          <button class="comments__button" >
            <i class="icon-comment-2 " ></i>
          </button>
          <button  class="ps-0 comments__arrow-btn">
            <i class="icon-triangle-arrow" :class="{'rotated': commentsVisible}"></i>
          </button>
        </div>
      </div>
    </div>

    <Transition>
      <div v-show="commentsVisible" class="horizontal-promo__comments mt-1">
        <comment-form ref="commentForm" @submit="pushComment"/>
        <comments-loading :loading="commentsLoading"/>
        <comments :comments-data="visibleCommentsData" @hit="hitCommentProcess"/>
        <show-more-comments :hidden-total="hiddenComments" :total="commentsCount" @show-more="showMoreComments"/>
      </div>
    </Transition>
  </div>
</template>

<script>
import {actions} from '@/mixins/post/actions';
import Share from '@/components/feed/Share.vue';
import CommentsLoading from '@/components/feed/comment/CommentsLoading.vue';
import Comments from '@/components/feed/comment/Comments.vue';
import {comments} from '@/mixins/comments';
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink.vue';
import ShowMoreComments from '@/components/feed/comment/ShowMoreComments.vue';
import {needAuth} from '@/mixins/auth/needAuth';


export default {
  name: 'HorizontalPromoLayout',
  components: {ShowMoreComments, RouterLocalizedLink, Comments, CommentsLoading, Share},
  mixins: [actions, comments, needAuth],
  props: {
    promo: Object,
    morphable_type: String,
    profileId: [Number, String],
    profileType: [String],
    avatar: {
      type: String,
    },
    name: {
      type: String,
    },
    promotedPromo: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    routeBody() {
      let routeBody = {name: 'home'};
      if (this.profileType === 'user') {
        routeBody = {name: 'profile', params: {userId: String(this.profileId)}};
      } else if (this.profileType === 'webshop') {
        routeBody = {name: 'webshop.show', params: {webshopSlug: this.profileId}};
      } else if (this.profileType === 'store') {
        routeBody = {name: 'folders.store', params: {storeSlug: this.profileId}};
      }
      return routeBody;
    },
    commentsLabel() {
      if (this.xsOnly) {
        return this.commentsCount
      }

      return this.commentsCount === 1 ? `${this.commentsCount} reactie` : `${this.commentsCount} reacties`
    },

  },
  data() {
    return {
      commentsVisible: false,
    }
  },
  mounted() {
    this.commentsCount = this.promo.comments_count;
    this.comments = this.promo.comments;
    this.visibleComments = _.min([3, this.promo.comments.length]);
  },
  methods: {
    /**
     * Share
     * @param destination
     */
    processShare(destination) {
      let routeName = ''
      switch (this.morphable_type) {
        case 'FolderItem':
          routeName = 'folder_promos.show'
          break;
        case 'OnlinePromo':
          routeName = 'online_promos.show'
          break;
        case 'Post':
          routeName = 'posts.show'
          break;
      }
      const link = new URL(
          this.$router.resolve({
            name: routeName,
            params: {id: this.promo.id}
          }).href,
          window.location.origin
      ).href;
      this.shareLink(link, destination);
    },
    showMoreComments() {
      this.showMoreCommentsProcess(this.morphable_type, this.promo.id);
    },
    pushComment(form) {
      this.pushCommentProcess(this.morphable_type, this.promo.id, form);
    }

  }
}
</script>



