<template>
  <div class="news-section" v-if="ARTICLES.length">
    <default-space>
      <div class="news-section__header">
        <h3 class="news-section__title" v-t="'landing.news.title'"/>

        <router-localized-link :to="{name: 'news'}" class="news-section__link d-none d-md-block">
          <span v-t="'landing.news.subtitle'"/>
          <i class="icon-triangle-arrow ms-2"/>
        </router-localized-link>
      </div>

      <news-carousel :news="ARTICLES"/>

      <router-localized-link :to="{name: 'news'}" class="news-section__link d-md-none">
        <span v-t="'landing.news.subtitle'"/>
        <i class="icon-triangle-arrow ms-2"/>
      </router-localized-link>
    </default-space>
  </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex';
import NewsCarousel from '@/components/feed/carousels/NewsCarousel.vue';
import DefaultSpace from '@/views/DefaultSpace.vue';
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink.vue';

export default {
  name: 'NewsSection',
  components: {RouterLocalizedLink, DefaultSpace, NewsCarousel},
  computed: {
    ...mapGetters('article', [
      'ARTICLES',
      'ARTICLES_PROMISE',
      'ARTICLES_TOTAL',
      'ARTICLES_CURRENT_PAGE',
      'ARTICLES_PER_PAGE',
      'MOST_RECENTLY',
      'LAST_YEAR',
      'LAST_WEEK'
    ]),
  },
  data() {
    return {
      defaultPage: _.get(this.$route.query, 'page', 1),
    }
  },
  mounted() {
    this.FETCH_ARTICLES({page: this.defaultPage}).then(() => {
      this.currentPage = this.ARTICLES_CURRENT_PAGE;
      this.totalArticles = this.ARTICLES_TOTAL;
    });
  },
  methods: {
    ...mapActions('article', [
      'FETCH_ARTICLES',
    ]),
    formattedArticles(arr) {
      const thisElem = this
      return _.map(arr, function (article) {
        const date = new Date(article.published_at.iso);

        return _.merge({}, article, {additional_title: thisElem.formatDate(date) + ' ' + article.translation.title});
      });
    },
  }
}
</script>
