import store from '@/store';

export const needAuth = {
  methods: {
    triggerLoginDialog(popup = false) {
      if (store.getters['auth/GUEST']) {
        if (popup) {
          this.$dialogs.show('auth')
        }else
        window.open(this.$router.resolve({name: 'login'}).href, '_self');
      }
      return store.getters['auth/GUEST'];
    },
    webshopLoginDialog(){
      const webshopRoute = _.get(this.$router.currentRoute.value, 'name')

      if (_.isEqual(webshopRoute, 'webshop.show') && this.$store.getters['auth/GUEST']) {
        this.emitter.emit('copy-discount-code', {
          data: {
            discount_url: _.get(this.$store.getters['webshop/CURRENT_WEBSHOP'], 'translation.affiliate_url'),
            discount_code: 'webshop'
          }
        })
      }
    }
  },
};
